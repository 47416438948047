import { generateMockValue } from '@utils/generateMockData';

export const generateDataServices = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        name: 'string',
        proformNote: 'string',
        note: 'string',
        renewPrice: 'number',
        renewPriceMarkup: 'number',
        renewRecurrence: 'string',
        toPay: 'boolean',
        toNotify: 'boolean',
        dateExpiry: 'string',
        toRenew: 'boolean',
        projects: {
          type: 'array',
          minLength: 1,
          maxLength: 1,
          each: {
            type: 'object',
            values: {
              projectId: 'number',
              renewPriceMarkup: 'number',
              renewRecurrence: 'string',
              toPay: 'boolean',
              toNotify: 'boolean',
              dateExpiry: 'string',
              project: {
                type: 'object',
                values: {
                  id: 'number',
                  name: 'string',
                  clientId: 'number',
                },
              },
            },
          },
        },
      },
    },
  });
};
