import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Container,
  Group,
  rem,
  Select,
  Space,
  Switch,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertCircle,
  IconCircleDashedNumber2,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import { setMinHeightTable } from '@utils/general';
import { numberToCurrency } from '@utils/number';

import socket from '@lib/socket';

import { isApiError } from '@api/index';
import { JobsStatusResponse, useGetJobsStatusQuery } from '@api/jobs/jobs.api';
import {
  Service,
  useDeleteServiceMutation,
  usePatchServiceMutation,
} from '@api/services/services.api';
import {
  CustomSecondaryServiceMap,
  CustomServiceShMap,
  ServiceSh,
  useDeleteServicesShMutation,
  usePatchServicesShMutation,
  useStartImportServicesJobMutation,
} from '@api/servicessh/servicesSh.api';

import ExpireDateWithTooltip from './ExpireDateWithTooltip';
import ModalAddService from './ModalAddService';
import ModalPatchService from './ModalPatchService';
import ModalPatchServiceSh from './modalPatchServiceSh/ModalPatchServiceSh';
import PaginationRow from './PaginationRow';
import SearchAddContainer from './SearchAddContainer';

interface ServicesTableProps {
  showProjectColumn?: boolean;
  servicesData: Service[];
  servicesPagination: {
    count: number;
    pageLength: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onPageLengthChange: (newPageLength: number) => void;
  };
  servicesSearchValue?: string;
  onServicesSearchValueChange: (value: string) => void;
  servicesDateExpiryFilter?: string;
  onServicesDateExpiryFilterChange: (value: string | null) => void;
  servicesShData: CustomServiceShMap[];
  servicesShPagination: {
    count: number;
    pageLength: number;
    page: number;
    onPageChange: (newPage: number) => void;
    onPageLengthChange: (newPageLength: number) => void;
  };
  servicesShSearchValue?: string;
  onServicesShSearchValueChange: (value: string) => void;
  servicesShDateExpiryFilter?: string;
  onServicesShDateExpiryFilterChange: (value: string | null) => void;
  servicesShProjectsAssociatedFilter?: string;
  servicesFooter?: { rinnovo: string | 0; miroir: string | 0 };
  servicesShFooter?: { rinnovo: string | 0; miroir: string | 0 };
  hideProfitColumn?: boolean;
  hideAddServicesButtons?: boolean;
  hideToPayColumn?: boolean;
  hideProjectsColumn?: boolean;
  hideToRenewColumn?: boolean;
  hideFilterAssociatedFilter: boolean;
  hideToNotifyColumn?: boolean;
  loadingServicesBool?: boolean;
  loadingServicesShBool?: boolean;
  errorServices?: boolean;
  errorServicesSh?: boolean;
  labelColumnRenewPriceMarkup: string;
  labelColumnDateExpiry: string;
  transferDataJobsStatus?: (childData: JobsStatusResponse) => void;
  setDateExpirySupplier?: boolean;
  // Sorting
  sortStatusService: DataTableSortStatus<Service>;
  setSortStatusService: React.Dispatch<
    React.SetStateAction<DataTableSortStatus<Service>>
  >;
  sortStatusServiceSh: DataTableSortStatus<CustomServiceShMap>;
  setSortStatusServiceSh: React.Dispatch<
    React.SetStateAction<DataTableSortStatus<CustomServiceShMap>>
  >;
}

function ServicesTables({
  transferDataJobsStatus,
  servicesData,
  servicesPagination,
  servicesSearchValue,
  onServicesSearchValueChange,
  servicesDateExpiryFilter,
  onServicesDateExpiryFilterChange,
  servicesShData,
  servicesShPagination,
  servicesShSearchValue,
  onServicesShSearchValueChange,
  servicesShDateExpiryFilter,
  onServicesShDateExpiryFilterChange,
  hideFilterAssociatedFilter,
  hideProfitColumn,
  hideAddServicesButtons,
  hideToRenewColumn,
  hideToNotifyColumn,
  loadingServicesBool,
  loadingServicesShBool,
  errorServices,
  hideToPayColumn,
  hideProjectsColumn,
  errorServicesSh,
  servicesFooter,
  servicesShFooter,
  sortStatusService,
  setSortStatusService,
  sortStatusServiceSh,
  setSortStatusServiceSh,
  labelColumnRenewPriceMarkup,
  labelColumnDateExpiry,
  setDateExpirySupplier,
}: ServicesTableProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { colorScheme } = useMantineColorScheme();
  const { id } = useParams();

  // ==========================================================================
  // State
  // ==========================================================================

  const [importServicesProgress, setImportServicesProgress] = useState(0);
  const theme = useMantineTheme();
  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: jobsStatus = {
      websiteScanner: {
        failed: [],
        completed: [],
        inProgress: [],
      },
      servicesImporter: {
        failed: [],
        completed: [],
        inProgress: [],
      },
    },
    isSuccess,
  } = useGetJobsStatusQuery();

  useEffect(() => {
    if (isSuccess && transferDataJobsStatus) {
      transferDataJobsStatus(jobsStatus);
    }
  }, [isSuccess, jobsStatus]);

  const [serviceShDelete, { isLoading: deleteShLoading }] =
    useDeleteServicesShMutation();

  const [
    startImportServicesJob,
    { isLoading: isLoadingStartImportServicesJob },
  ] = useStartImportServicesJobMutation();

  const [patchServiceSh] = usePatchServicesShMutation();

  const changeServiceShToRenew = async (id: number, toRenew: boolean) => {
    try {
      await patchServiceSh({
        id,
        body: { toRenew },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const changeServiceShToNotify = async (
    serviceId: number,
    toNotify: boolean,
  ) => {
    try {
      await patchServiceSh({
        id: serviceId,
        body: { projectsToNotify: [{ projectId: +id!, toNotify: toNotify }] },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const [serviceDelete, { isLoading: deleteLoading }] =
    useDeleteServiceMutation();

  const [patchService] = usePatchServiceMutation();

  const changeServiceToRenew = async (id: number, toRenew: boolean) => {
    try {
      await patchService({
        id,
        body: { toRenew },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const changeServiceToNotify = async (
    serviceId: number,
    toNotify: boolean,
  ) => {
    try {
      await patchService({
        id: serviceId,
        body: { projectsToNotify: [{ projectId: +id!, toNotify: toNotify }] },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const onStartImportClick = async () => {
    try {
      await startImportServicesJob().unwrap();
    } catch {
      showNotification({
        color: 'red',
        title: 'Errore job',
        message: 'Un altro job è già in elaborazione',
      });
    }
  };

  const onEditServiceClick = async (
    service: Service,
    setDateExpirySupplier: boolean,
  ) => {
    openModal({
      title: 'Modifica servizio',
      children: (
        <ModalPatchService
          service={service}
          setDateExpirySupplier={setDateExpirySupplier}
        />
      ),
    });
  };

  const onEditServiceShClick = async (
    serviceSh: ServiceSh,
    setDateExpirySupplier: boolean,
  ) => {
    openModal({
      title: 'Modifica servizio',
      children: (
        <ModalPatchServiceSh
          serviceSh={serviceSh}
          setDateExpirySupplier={setDateExpirySupplier}
        />
      ),
    });
  };

  // Progress socket
  useEffect(() => {
    const onImportProgress = (data: {
      id: number;
      category: string;
      progress: number;
    }) => {
      if (data.category === 'servicesImporter') {
        setImportServicesProgress(data.progress);
      }
    };

    // Shellrent import progress
    socket.on('job_progress', onImportProgress);

    return () => {
      socket.off('job_progress', onImportProgress);
    };
  }, []);

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPagesService = Math.ceil(
    servicesPagination.count / servicesPagination.pageLength,
  );

  const totalPagesSh = Math.ceil(
    servicesShPagination.count / servicesShPagination.pageLength,
  );

  const dateExpiryFilter = (
    <Select
      value={servicesDateExpiryFilter}
      onChange={onServicesDateExpiryFilterChange}
      placeholder="Filtro scadenza"
      data={[
        { label: 'Tutti i servizi', value: 'all' },
        { label: 'Servizi scaduti', value: 'expired' },
        { label: 'Servizi non scaduti', value: 'notExpired' },
        { label: 'Servizi in scadenza', value: 'almostExpired' },
      ]}
      allowDeselect={false}
    />
  );

  const dateExpiryShFilter = (
    <Select
      value={servicesShDateExpiryFilter}
      onChange={onServicesShDateExpiryFilterChange}
      placeholder="Filtro scadenza"
      data={[
        { label: 'Tutti i servizi', value: 'all' },
        { label: 'Servizi scaduti', value: 'expired' },
        { label: 'Servizi non scaduti', value: 'notExpired' },
        { label: 'Servizi in scadenza', value: 'almostExpired' },
      ]}
      allowDeselect={false}
    />
  );

  const calculatePriceRenewPriceMarkup = (
    record: CustomServiceShMap | CustomSecondaryServiceMap | Service,
  ) => {
    const renewPriceMarkup = record.projects.reduce(
      (acc, project) => acc + project.renewPriceMarkup,
      0,
    );

    let totalRenewPrice = 0;

    // Itera su tutti i progetti
    record.projects.forEach((project) => {
      let price = 0;

      if (project?.renewRecurrence?.startsWith('M')) {
        price = project.renewPriceMarkup * 12;
      } else if (project?.renewRecurrence?.startsWith('Y')) {
        price = project.renewPriceMarkup;
      }

      totalRenewPrice += price;
    });

    return { renewPriceMarkup, totalRenewPrice };
  };

  const calculatePriceRenewPrice = (
    record: CustomServiceShMap | CustomSecondaryServiceMap | Service,
  ) => {
    let price = 0;

    if (record?.renewRecurrence?.startsWith('M')) {
      price = record.renewPrice * 12;
    } else if (record?.renewRecurrence?.startsWith('Y')) {
      price = record.renewPrice;
    }

    return price;
  };

  const calculateProfit = (
    record: CustomServiceShMap | CustomSecondaryServiceMap | Service,
  ) => {
    const { renewPriceMarkup, totalRenewPrice } =
      calculatePriceRenewPriceMarkup(record);

    const renewPrice = calculatePriceRenewPrice(record);

    const profit = (totalRenewPrice || renewPriceMarkup) - renewPrice;

    return profit;
  };
  return (
    <>
      {/* Mantine Datatable ServiceSH*/}
      <SearchAddContainer
        title={`Servizi Shellrent (${servicesShPagination.count})`}
        additionalFilters={
          hideFilterAssociatedFilter ? [dateExpiryShFilter] : dateExpiryShFilter
        }
        searchPlaceholder="Ricerca per nome"
        searchValue={servicesShSearchValue}
        onSearchChange={onServicesShSearchValueChange}
        rightSection={
          hideAddServicesButtons ? undefined : (
            <>
              {jobsStatus.servicesImporter.failed.length > 0 && (
                <Box
                  bg="#3b2326"
                  py={rem('0.35rem')}
                  px={rem('1rem')}
                  style={{ borderRadius: theme.radius?.md }}
                >
                  <Group>Importazione fallita</Group>
                </Box>
              )}

              <Button
                onClick={onStartImportClick}
                loading={
                  isLoadingStartImportServicesJob ||
                  jobsStatus.servicesImporter.inProgress.length > 0 ||
                  false
                }
                loaderProps={
                  jobsStatus.servicesImporter.inProgress.length > 0 &&
                  importServicesProgress > 0 &&
                  importServicesProgress < 100
                    ? {
                        children: `${importServicesProgress}%`,
                      }
                    : undefined
                }
              >
                {jobsStatus.servicesImporter.failed.length > 0
                  ? 'Avvia nuova importazione'
                  : 'Avvia importazione'}
              </Button>
            </>
          )
        }
      >
        <DataTable
          withRowBorders
          highlightOnHover
          striped
          minHeight={setMinHeightTable(servicesShData)}
          styles={{
            root: {
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
            },
            header: {
              backgroundColor: colorScheme === 'dark' ? '#1e2023' : '',
            },
          }}
          columns={[
            {
              accessor: 'toRenew',
              title: 'Da rinnovare',
              width: '0%',
              hidden: hideToRenewColumn,
              render: (record: CustomServiceShMap) => (
                <Group wrap="nowrap">
                  <Switch
                    checked={record.toRenew}
                    onChange={(e) =>
                      changeServiceShToRenew(record.id, e.currentTarget.checked)
                    }
                    style={{ justifyContent: 'center' }}
                  />
                  {record.projects.length === 0 && (
                    <Tooltip label="Il servizio non é associato a nessun progetto o cliente">
                      <Group>
                        <IconAlertCircle color="#e01818" />
                      </Group>
                    </Tooltip>
                  )}
                </Group>
              ),
            },
            {
              accessor: 'toNotify',
              title: 'Da notificare',
              width: '0%',
              hidden: hideToNotifyColumn,
              render: (record: CustomServiceShMap) => (
                <Switch
                  checked={record.toNotify}
                  onChange={(e) =>
                    changeServiceShToNotify(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            {
              accessor: 'shellrentId',
              title: 'Id Sh',
              width: '0%',
              sortable: true,
              footer: servicesShFooter && (
                <Text style={{ fontWeight: 'bold' }}>Totali:</Text>
              ),
            },
            {
              accessor: 'name',
              title: 'Nome',
              sortable: true,
              render: (record: CustomServiceShMap) => (
                <Group justify="space-between" wrap="nowrap">
                  {record.name}
                  {record.secondaryServicesMapped &&
                    record.secondaryServicesMapped.length > 0 && (
                      <IconCircleDashedNumber2 />
                    )}
                </Group>
              ),
            },
            {
              accessor: 'dateExpiry',
              title: labelColumnDateExpiry,
              width: '0%',
              sortable: true,
              render: (record: CustomServiceShMap) => (
                <ExpireDateWithTooltip date={new Date(record.dateExpiry)} />
              ),
            },
            {
              accessor: 'renewPrice',
              title: 'Costo rinnovo fornitore (anno)',
              width: '0%',
              render: (record: CustomServiceShMap) => {
                const price = calculatePriceRenewPrice(record);

                return <>€ {numberToCurrency(price)}</>;
              },
              footer: servicesShFooter && (
                <Text fw={700}>
                  € {servicesShFooter && servicesShFooter.rinnovo}
                </Text>
              ),
            },
            {
              accessor: 'renewPriceMarkup',
              title: labelColumnRenewPriceMarkup,
              width: '0%',
              render: (record: CustomServiceShMap) => {
                const { renewPriceMarkup, totalRenewPrice } =
                  calculatePriceRenewPriceMarkup(record);

                return (
                  <>€{numberToCurrency(totalRenewPrice || renewPriceMarkup)}</>
                );
              },
              footer: servicesShFooter && (
                <Text fw={700}>€ {servicesShFooter?.miroir}</Text>
              ),
            },
            {
              accessor: 'profit',
              title: 'Guadagno (anno)',
              width: '0%',
              hidden: hideProfitColumn,
              render: (record: CustomServiceShMap) => {
                const profit = calculateProfit(record);

                return (
                  <>
                    {record.projects.length === 0
                      ? '-'
                      : `€${numberToCurrency(profit)}`}
                  </>
                );
              },
            },
            {
              accessor: 'toPay',
              title: 'Stato pagamento',
              width: '0%',
              hidden: hideToPayColumn,
              render: (record: CustomServiceShMap) =>
                record.toPay ? (
                  <Badge variant="filled" color="#c00404" radius="sm">
                    Non pagato
                  </Badge>
                ) : (
                  <Badge variant="filled" color="green" radius="sm">
                    Pagato
                  </Badge>
                ),
            },
            {
              accessor: 'Projects',
              title: 'Progetti',
              width: '0%',
              hidden: hideProjectsColumn,
              render: (record: CustomServiceShMap) => (
                <Text>{record.projects.length}</Text>
              ),
            },
            {
              accessor: 'actions',
              title: '',
              width: '0%',
              render: (record: CustomServiceShMap) => (
                <Group justify="flex-end" wrap="nowrap">
                  <ActionIcon
                    title="Modifica"
                    onClick={() =>
                      onEditServiceShClick(
                        record,
                        setDateExpirySupplier === true ? true : false,
                      )
                    }
                  >
                    <IconPencil />
                  </ActionIcon>
                  <ActionIcon
                    title="Elimina"
                    loading={deleteShLoading}
                    onClick={async () => {
                      openConfirmModal({
                        title: 'Eliminazione servizio',
                        size: 'lg',
                        children: (
                          <Text>
                            Stai per eliminare il servizio {record.name}. Sicuro
                            di voler continuare?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Conferma eliminazione',
                          cancel: 'Annulla',
                        },
                        confirmProps: { color: '#c00404' },
                        onConfirm: async () => {
                          try {
                            await serviceShDelete({
                              id: record.id,
                            }).unwrap();
                            showNotification({
                              title: 'Servizio eliminato',
                              message:
                                "L'eliminazione del servizio è avvenuta con successo",
                            });
                          } catch (e) {
                            console.error(e);
                            if (isApiError(e)) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
          records={servicesShData}
          fetching={loadingServicesShBool}
          rowExpansion={{
            allowMultiple: true,
            expandable: ({ record }: { record: CustomServiceShMap }) =>
              record.secondaryServicesMapped &&
              record.secondaryServicesMapped.length > 0,
            content: ({ record }) => (
              <Container p="md" size="100%">
                <DataTable
                  highlightOnHover
                  withRowBorders
                  withTableBorder
                  striped
                  styles={{
                    root: {
                      borderRadius: theme.radius.md,
                    },
                  }}
                  records={
                    record.secondaryServicesMapped as CustomSecondaryServiceMap[]
                  }
                  columns={[
                    {
                      accessor: 'toRenew',
                      title: 'Da rinnovare',
                      width: '0%',
                      hidden: hideToRenewColumn,
                      render: (record: CustomSecondaryServiceMap) => (
                        <Group wrap="nowrap">
                          <Switch
                            checked={record.toRenew}
                            onChange={(e) =>
                              changeServiceShToRenew(
                                record.id,
                                e.currentTarget.checked,
                              )
                            }
                            style={{ justifyContent: 'center' }}
                          />
                          {record.projects.length === 0 && (
                            <Tooltip label="Il servizio non é associato a nessun progetto o cliente">
                              <Group>
                                <IconAlertCircle color="#c00404" />
                              </Group>
                            </Tooltip>
                          )}
                        </Group>
                      ),
                    },
                    {
                      accessor: 'toNotify',
                      title: 'Da notificare',
                      width: '0%',
                      hidden: hideToNotifyColumn,
                      render: (record: CustomSecondaryServiceMap) => (
                        <Switch
                          checked={record.toNotify}
                          onChange={(e) =>
                            changeServiceShToNotify(
                              record.id,
                              e.currentTarget.checked,
                            )
                          }
                          style={{ justifyContent: 'center' }}
                        />
                      ),
                    },
                    { accessor: 'id', title: 'Id Sh', width: '0%' },
                    { accessor: 'name', title: 'Nome' },
                    {
                      accessor: 'dateExpiry',
                      title: labelColumnDateExpiry,
                      width: '0%',
                      render: (record: CustomSecondaryServiceMap) => (
                        <ExpireDateWithTooltip
                          date={new Date(record.dateExpiry)}
                        />
                      ),
                    },
                    {
                      accessor: 'renewPrice',
                      title: 'Costo rinnovo fornitore (anno)',
                      width: '0%',
                      render: (record: CustomSecondaryServiceMap) => {
                        const price = calculatePriceRenewPrice(record);

                        return <>€ {numberToCurrency(price)}</>;
                      },
                    },
                    {
                      accessor: 'renewPriceMarkup',
                      title: labelColumnRenewPriceMarkup,
                      width: '0%',
                      render: (record: CustomSecondaryServiceMap) => {
                        const { renewPriceMarkup, totalRenewPrice } =
                          calculatePriceRenewPriceMarkup(record);

                        console.log(renewPriceMarkup, totalRenewPrice);

                        return (
                          <>
                            €
                            {numberToCurrency(
                              totalRenewPrice || renewPriceMarkup,
                            )}
                          </>
                        );
                      },
                    },
                    {
                      accessor: 'profit',
                      title: 'Guadagno (anno)',
                      width: '0%',
                      hidden: hideProfitColumn,
                      render: (record: CustomSecondaryServiceMap) => {
                        const profit = calculateProfit(record);

                        return <>€{numberToCurrency(profit)}</>;
                      },
                    },
                    {
                      accessor: 'Projects',
                      title: 'Progetti',
                      width: '0%',
                      hidden: hideProjectsColumn,
                      render: (record: CustomSecondaryServiceMap) =>
                        record.projects.length,
                    },
                    {
                      accessor: 'actions',
                      width: '0%',
                      title: '',
                      render: (record: CustomSecondaryServiceMap) => (
                        <Group justify="flex-end" wrap="nowrap">
                          <ActionIcon
                            title="Modifica"
                            onClick={() =>
                              onEditServiceShClick(
                                record,
                                setDateExpirySupplier === true ? true : false,
                              )
                            }
                          >
                            <IconPencil />
                          </ActionIcon>
                          <ActionIcon
                            title="Elimina"
                            loading={deleteShLoading}
                            onClick={async () => {
                              openConfirmModal({
                                title: 'Eliminazione servizio',
                                size: 'lg',
                                children: (
                                  <Text>
                                    Stai per eliminare il servizio {record.name}
                                    . Sicuro di voler continuare?
                                  </Text>
                                ),
                                labels: {
                                  confirm: 'Conferma eliminazione',
                                  cancel: 'Annulla',
                                },
                                confirmProps: { color: '#c00404' },
                                onConfirm: async () => {
                                  try {
                                    await serviceShDelete({
                                      id: record.id,
                                    }).unwrap();
                                    showNotification({
                                      title: 'Servizio eliminato',
                                      message:
                                        "L'eliminazione del servizio è avvenuta con successo",
                                    });
                                  } catch (e) {
                                    console.error(e);
                                    if (isApiError(e)) {
                                      showNotification({
                                        color: 'red',
                                        title: 'Errore',
                                        message: `${e.data.message}`,
                                      });
                                    }
                                  }
                                },
                              });
                            }}
                          >
                            <IconTrash />
                          </ActionIcon>
                        </Group>
                      ),
                    },
                  ]}
                ></DataTable>
              </Container>
            ),
          }}
          sortStatus={sortStatusServiceSh}
          onSortStatusChange={setSortStatusServiceSh}
          noRecordsText={
            errorServicesSh
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun servizio trovato'
          }
        ></DataTable>
        {servicesShData.length > 0 && (
          <PaginationRow
            searchParamsKey="Sh"
            page={servicesShPagination.page}
            pageLength={servicesShPagination.pageLength}
            totalPages={totalPagesSh}
            onPageChange={servicesShPagination.onPageChange}
            onPageLengthChange={servicesShPagination.onPageLengthChange}
          />
        )}
      </SearchAddContainer>

      <Space h={'xl'} />

      {/* Mantine Datatable Service*/}
      <SearchAddContainer
        title={`Servizi (${servicesPagination.count})`}
        additionalFilters={dateExpiryFilter}
        searchPlaceholder="Ricerca per nome"
        searchValue={servicesSearchValue}
        onSearchChange={onServicesSearchValueChange}
        onActionButtonClick={
          hideAddServicesButtons
            ? undefined
            : () =>
                openModal({
                  title: 'Inserisci nuovo servizio',
                  children: <ModalAddService />,
                })
        }
      >
        {/* Mantine Datatable Service */}
        <DataTable
          highlightOnHover
          minHeight={setMinHeightTable(servicesData)}
          withRowBorders
          striped
          styles={{
            root: {
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
            },
            header: {
              backgroundColor: colorScheme === 'dark' ? '#1e2023' : '',
            },
          }}
          columns={[
            {
              accessor: 'toRenew',
              title: 'Da rinnovare',
              width: '0%',
              hidden: hideToRenewColumn,
              render: (record: Service) => (
                <Switch
                  checked={record.toRenew}
                  onChange={(e) =>
                    changeServiceToRenew(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            {
              accessor: 'toNotify',
              title: 'Da notificare',
              width: '0%',
              hidden: hideToNotifyColumn,
              render: (record: Service) => (
                <Switch
                  checked={record.toNotify}
                  onChange={(e) =>
                    changeServiceToNotify(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            {
              accessor: 'name',
              title: 'Nome',
              sortable: true,
              footer: servicesFooter && servicesData.length > 0 && (
                <Text style={{ fontWeight: 'bold' }}>Totali:</Text>
              ),
            },
            {
              accessor: 'dateExpiry',
              title: labelColumnDateExpiry,
              width: '0%',
              sortable: true,
              render: (record: Service) => (
                <ExpireDateWithTooltip date={new Date(record.dateExpiry)} />
              ),
            },
            {
              accessor: 'renewPrice',
              title: 'Costo rinnovo fornitore (anno)',
              width: '0%',
              render: (record: Service) => {
                const price = calculatePriceRenewPrice(record);
                return <>€{numberToCurrency(price)}</>;
              },
              footer: servicesFooter && (
                <Text fw={700}>€ {servicesFooter?.rinnovo}</Text>
              ),
            },
            {
              accessor: 'renewPriceMarkup',
              title: labelColumnRenewPriceMarkup,
              width: '0%',
              render: (record: Service) => {
                const { renewPriceMarkup, totalRenewPrice } =
                  calculatePriceRenewPriceMarkup(record);

                return (
                  <>€{numberToCurrency(totalRenewPrice || renewPriceMarkup)}</>
                );
              },
              footer: servicesFooter && (
                <Text fw={700}>€ {servicesFooter?.miroir}</Text>
              ),
            },
            {
              accessor: 'profit',
              title: 'Guadagno (anno)',
              width: '0%',
              hidden: hideProfitColumn,
              render: (record: Service) => {
                const profit = calculateProfit(record);

                return <>€{numberToCurrency(profit)}</>;
              },
            },
            {
              accessor: 'toPay',
              title: 'Stato pagamento',
              width: '0%',
              hidden: hideToPayColumn,
              render: (record: Service) =>
                record.toPay ? (
                  <Badge variant="filled" color="#c00404" radius="sm">
                    Non pagato
                  </Badge>
                ) : (
                  <Badge variant="filled" color="green" radius="sm">
                    Pagato
                  </Badge>
                ),
            },
            {
              accessor: 'Projects',
              title: 'Progetti',
              width: '0%',
              hidden: hideProjectsColumn,
              render: (record: Service) => record.projects.length,
            },
            {
              accessor: 'actions',
              width: '0%',
              title: '',
              render: (record: Service) => (
                <Group justify="flex-end" wrap="nowrap">
                  <ActionIcon title="Modifica">
                    <IconPencil
                      onClick={() =>
                        onEditServiceClick(
                          record,
                          setDateExpirySupplier === true ? true : false,
                        )
                      }
                    />
                  </ActionIcon>
                  <ActionIcon
                    title="Elimina"
                    loading={deleteLoading}
                    onClick={() => {
                      openConfirmModal({
                        title: 'Eliminazione servizio',
                        size: 'lg',
                        children: (
                          <Text>
                            Stai per eliminare il servizio {record.name}. Sicuro
                            di voler continuare?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Conferma eliminazione',
                          cancel: 'Annulla',
                        },
                        confirmProps: { color: 'red' },
                        onConfirm: async () => {
                          try {
                            await serviceDelete({ id: record.id }).unwrap();
                            showNotification({
                              title: 'Servizio eliminato',
                              message:
                                "L'eliminazione del servizio è avvenuta con successo",
                            });
                          } catch (e) {
                            console.error(e);
                            if (isApiError(e)) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
          records={servicesData}
          fetching={loadingServicesBool}
          noRecordsText={
            errorServices
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun servizio trovato'
          }
          sortStatus={sortStatusService}
          onSortStatusChange={setSortStatusService}
        ></DataTable>
        {servicesData.length > 0 && (
          <PaginationRow
            page={servicesPagination.page}
            pageLength={servicesPagination.pageLength}
            totalPages={totalPagesService}
            onPageChange={servicesPagination.onPageChange}
            onPageLengthChange={servicesPagination.onPageLengthChange}
          />
        )}
      </SearchAddContainer>
    </>
  );
}

export default ServicesTables;
