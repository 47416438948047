import 'dayjs/locale/it';

import { useState } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Group,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { handleSubmitError } from '@utils/general';

import { ProjectForSelector } from '@api/projects/projects.api';
import {
  ProjectDateExpiry,
  ProjectRenewPriceMarkups,
  usePostAddServiceMutation,
} from '@api/services/services.api';

import MultiProjectsSelector from './MultiProjectsSelector';

type ModalAddServiceProps = ModalProps;

export default function ModalAddServic({
  closeAllOnConfirm = true,
  onConfirmed,
}: ModalAddServiceProps) {
  // ==========================================================================
  // State
  // ==========================================================================
  const [isEditingProjectsDetails, setIsEditingProjectsDetails] =
    useState(false);

  // const [renewPriceMiroirTotal, setRenewPriceMiroirTotal] = useState(0);
  const [projectsRenewPriceMarkups, setProjectsRenewPriceMarkups] = useState<
    ProjectRenewPriceMarkups[]
  >([]);

  const [projectsRenewRecurrence, setProjectsRenewRecurrence] = useState<
    { projectId: number; renewRecurrence: string }[]
  >([]);

  const [projectsNames, setProjectsNames] = useState<ProjectForSelector[]>([]);

  const [projectsDateExpiry, setProjectsDateExpiry] = useState<
    ProjectDateExpiry[]
  >([]);

  // ==========================================================================
  // Handler
  // ==========================================================================
  const onChangeProjects = (value: string[]) => {
    form.setFieldValue('projectsIds', value);

    // Remove deselected projects
    const newRenewPriceMarkups = projectsRenewPriceMarkups.filter((project) =>
      value.includes(project.projectId.toString()),
    );

    const newDateExpiry = projectsDateExpiry.filter((project) =>
      value.includes(project.projectId.toString()),
    );

    const newRecurrence = projectsRenewRecurrence.filter((project) =>
      value.includes(project.projectId.toString()),
    );

    // Add new projects
    for (const projectId of value) {
      if (
        !newRenewPriceMarkups.find(
          (project) => project.projectId.toString() === projectId,
        )
      ) {
        newRenewPriceMarkups.push({
          projectId: +projectId,
          renewPriceMarkup: form.values.renewPrice,
        });
      }

      if (
        !newDateExpiry.find(
          (project) => project.projectId.toString() === projectId,
        )
      ) {
        newDateExpiry.push({
          projectId: +projectId,
          dateExpiry: form.values.dateExpiry,
        });
      }

      if (
        !newRecurrence.find(
          (project) => project.projectId.toString() === projectId,
        )
      ) {
        newRecurrence.push({
          projectId: +projectId,
          renewRecurrence: 'Y1', // valore predefinito
        });
      }
    }

    setProjectsRenewPriceMarkups(newRenewPriceMarkups);
    // setRenewPriceMiroirTotal(
    //   newRenewPriceMarkups.reduce(
    //     (acc, project) => acc + project.renewPriceMarkup,
    //     0,
    //   ),
    // );
    setProjectsDateExpiry(newDateExpiry);
    setProjectsRenewRecurrence(newRecurrence);
  };

  const onChangeRenewPriceMarkup = (value: number, projectId: number) => {
    const newRenewPriceMarkups = projectsRenewPriceMarkups.map((project) =>
      project.projectId === projectId
        ? { ...project, renewPriceMarkup: value }
        : project,
    );

    setProjectsRenewPriceMarkups(newRenewPriceMarkups);
    // setRenewPriceMiroirTotal(
    //   newRenewPriceMarkups.reduce(
    //     (acc, project) => acc + project.renewPriceMarkup,
    //     0,
    //   ),
    // );
  };

  const onChangeDateExpiry = (value: Date, projectId: number) => {
    const newDateExpiry = projectsDateExpiry.map((project) =>
      project.projectId === projectId
        ? { ...project, dateExpiry: value }
        : project,
    );

    setProjectsDateExpiry(newDateExpiry);
  };

  const onChangeRecurrence = (value: string, projectId: number) => {
    const newRecurrence = projectsRenewRecurrence.map((project) =>
      project.projectId === projectId
        ? { ...project, renewRecurrence: value }
        : project,
    );

    setProjectsRenewRecurrence(newRecurrence);
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [postService, { isLoading }] = usePostAddServiceMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: '',
    proformNote: '',
    note: '',
    renewPrice: 0,
    dateExpiry: new Date(),
    renewRecurrence: 'Y1',
    projectsIds: [] as string[],
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await postService({
        ...Object.fromEntries(
          Object.entries(values).map(([key, value]) => {
            if (typeof value === 'string') {
              return value.trim() === '' ? [key, null] : [key, value];
            } else {
              return [key, value];
            }
          }),
        ),
        dateExpiry: values.dateExpiry.toISOString(),
        projectsRenewPriceMarkups,
        projectsDateExpiry: projectsDateExpiry.map((p) => ({
          ...p,
          dateExpiry: (p.dateExpiry as Date).toISOString(),
        })),
        projectsRenewRecurrence: projectsRenewRecurrence.map((p) => ({
          ...p,
        })),
        projectsIds: values.projectsIds.map((projectId) => +projectId),
      }).unwrap();

      showNotification({
        title: 'Servizio aggiunto',
        message: 'Il servizio è stato aggiunto con successo',
      });

      onConfirmed?.();
      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={6}>
          <Title order={6}>DATI PRINCIPALI</Title>
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={6}>COSTI E SCADENZE</Title>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput required label="Nome:" {...form.getInputProps('name')} />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Rinnovo"
            placeholder="Ricorrenza pagamenti"
            defaultValue={'Y1'}
            data={[
              { label: 'Annuale', value: 'Y1' },
              { label: 'Mensile', value: 'M1' },
            ]}
            {...form.getInputProps('renewRecurrence')}
            allowDeselect={false}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <TextInput
            label="Nota proforma:"
            {...form.getInputProps('proformNote')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput
            required
            hideControls
            step={0.5}
            label="Costo rinnovo fornitore:"
            decimalScale={2}
            leftSection={<span>€</span>}
            {...form.getInputProps('renewPrice')}
          />
        </Grid.Col>
        {/* <Grid.Col span={6}>
          <NumberInput
            hideControls
            disabled
            label="Costo rinnovo Miroir:"
            step={0.5}
            decimalScale={2}
            leftSection={<span>€</span>}
            value={renewPriceMiroirTotal}
          />
        </Grid.Col> */}

        {/* ROW */}
        <Grid.Col span={6}>
          <Textarea
            label="Note:"
            {...form.getInputProps('note')}
            autosize
            minRows={1}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <DatePickerInput
            locale="it"
            label="Data rinnovo fornitore:"
            valueFormat="DD/MM/YYYY"
            {...form.getInputProps('dateExpiry')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <MultiProjectsSelector
            onChange={onChangeProjects}
            value={form.values.projectsIds}
            onProjectsFetched={setProjectsNames}
          />
        </Grid.Col>
        {/* ROW */}
        <Grid.Col>
          <Group
            role="button"
            title="Espandi"
            onClick={() =>
              setIsEditingProjectsDetails(!isEditingProjectsDetails)
            }
            style={{ cursor: 'pointer' }}
          >
            <Title order={6}>INFORMAZIONI PROGETTI</Title>

            {isEditingProjectsDetails ? <IconChevronUp /> : <IconChevronDown />}
          </Group>
          <Collapse in={isEditingProjectsDetails} pt="sm">
            {form.values.projectsIds.length === 0 && (
              <Text>Nessun progetto selezionato</Text>
            )}
            {form.values.projectsIds.map((projectId) => (
              <Box key={projectId} mb="xs">
                <Text mb="xs" style={{ fontWeight: 'bold' }}>
                  {projectsNames.find((p) => p.id === +projectId)?.name || ''}
                </Text>
                <Group>
                  <Select
                    label="Ricorrenza"
                    placeholder="Ricorrenza pagamenti"
                    value={
                      projectsRenewRecurrence.find(
                        (p) => p.projectId === +projectId,
                      )?.renewRecurrence || 'Y1'
                    }
                    data={[
                      { label: 'Annuale', value: 'Y1' },
                      { label: 'Mensile', value: 'M1' },
                    ]}
                    onChange={(value) => onChangeRecurrence(value!, +projectId)}
                    allowDeselect={false}
                  />
                  <NumberInput
                    label="Costo rinnovo cliente"
                    hideControls
                    required
                    step={0.5}
                    decimalScale={2}
                    leftSection={<span>€</span>}
                    value={
                      projectsRenewPriceMarkups.find(
                        (p) => p.projectId === +projectId,
                      )?.renewPriceMarkup || 0
                    }
                    onChange={(value) =>
                      onChangeRenewPriceMarkup(+value!, +projectId)
                    }
                  />
                  <DatePickerInput
                    label="Data rinnovo cliente"
                    locale="it"
                    valueFormat="DD/MM/YYYY"
                    value={
                      projectsDateExpiry.find((p) => p.projectId === +projectId)
                        ?.dateExpiry as Date
                    }
                    onChange={(value) => onChangeDateExpiry(value!, +projectId)}
                  />
                </Group>
                <Divider my="sm" />
              </Box>
            ))}
          </Collapse>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={10} mt="md">
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
