import { DataTableSortStatus } from 'mantine-datatable';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Badge,
  Container,
  Group,
  Paper,
  rem,
  Select,
  Space,
  Switch,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCategory,
  IconCategory2,
  IconCircleDashedNumber2,
  IconFileText,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import { numberToCurrency } from '@utils/number';
import { getYearlyPriceFromRecurrence } from '@utils/prices';

import { isApiError } from '@api/index';
import { useGetProformByProjectMutation } from '@api/proforms/proforms.api';
import { useGetSingleProjectQuery } from '@api/projects/projects.api';
import {
  DateExpiryFilter,
  Service,
  ServiceApiQueryParams,
  SortBy,
  useDeleteServiceMutation,
  useGetProjectServicesCountQuery,
  useGetProjectServicesQuery,
  useGetProjectTotServiceQuery,
  usePatchServiceMutation,
} from '@api/services/services.api';
import { generateDataServices } from '@api/services/services.mock';
import {
  ServiceSh,
  ServiceShApiQueryParams,
  SortBySh,
  useDeleteServicesShMutation,
  useGetProjectServicesShCountQuery,
  useGetProjectServicesShQuery,
  useGetProjectTotServiceShQuery,
  usePatchServicesShMutation,
} from '@api/servicessh/servicesSh.api';
import { generateDataServicessh } from '@api/servicessh/servicesSh.mock';

import ExpireDateWithTooltip from '@components/ExpireDateWithTooltip';
import useLayoutProps from '@components/layout/useLayoutProps';
import ModalPatchService from '@components/ModalPatchService';
import ModalPatchServiceSh from '@components/modalPatchServiceSh/ModalPatchServiceSh';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';
import StandardDataTable from '@components/standardDataTable/StandardDataTable';

import classes from './ProjectsServices.module.css';

const ProjectServices: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================

  const [filtersServiceSh, setFiltersServiceSh] =
    useState<ServiceShApiQueryParams>({
      page: +(searchParams.get('page') || 1),
      pageLength: +(searchParams.get('pageLength') || 20),
      searchQuery: '',
      dateExpiryFilter: searchParams.get('dateExpiryShFilter')
        ? (searchParams.get('dateExpiryShFilter') as DateExpiryFilter)
        : undefined,
    });

  const [searchQuerySh] = useDebouncedValue(filtersServiceSh.searchQuery, 200, {
    leading: true,
  });

  const [filtersService, setFiltersService] = useState<ServiceApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 20),
    searchQuery: '',
    dateExpiryFilter: searchParams.get('dateExpiryFilter')
      ? (searchParams.get('dateExpiryFilter') as DateExpiryFilter)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filtersService.searchQuery, 200, {
    leading: true,
  });

  const [sortStatusService, setSortStatusService] = useState<
    DataTableSortStatus<Service>
  >({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const [sortStatusServiceSh, setSortStatusServiceSh] = useState<
    DataTableSortStatus<ServiceSh>
  >({
    columnAccessor: 'dateExpiry',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatusService) {
      setFiltersService((f: ServiceApiQueryParams) => ({
        ...f,
        sortBy: sortStatusService.columnAccessor as SortBy,
        sortOrder: sortStatusService.direction,
      }));
    }

    if (sortStatusServiceSh) {
      setFiltersServiceSh((f: ServiceShApiQueryParams) => ({
        ...f,
        sortBy: sortStatusServiceSh.columnAccessor as SortBySh,
        sortOrder: sortStatusServiceSh.direction,
      }));
    }
  }, [sortStatusService, sortStatusServiceSh]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: shCount = { count: 0 } } = useGetProjectServicesShCountQuery({
    id: id!,
    params: {
      searchQuery: searchQuerySh,
      dateExpiryFilter: filtersServiceSh.dateExpiryFilter,
    },
  });

  const {
    data: servicesSh = generateDataServicessh(4),
    isLoading: isLoadingSh,
    error: errorServicesSh,
  } = useGetProjectServicesShQuery({
    id: id!,
    params: {
      ...filtersServiceSh,
      searchQuery: searchQuerySh,
    },
  });

  const { data: servicesCount = { count: 0 } } =
    useGetProjectServicesCountQuery({
      id: id!,
      params: {
        searchQuery: searchQuery,
        dateExpiryFilter: filtersService.dateExpiryFilter,
      },
    });

  const {
    data: services = generateDataServices(2),
    isLoading: isLoadingServices,
    error: errorServices,
  } = useGetProjectServicesQuery({
    id: id!,
    params: {
      ...filtersService,
      searchQuery,
    },
  });

  const { data: project } = useGetSingleProjectQuery(+id!);

  const [getProform, { isLoading: isLoadingProform }] =
    useGetProformByProjectMutation();

  const [patchServiceSh] = usePatchServicesShMutation();
  const [serviceShDelete, { isLoading: deleteShLoading }] =
    useDeleteServicesShMutation();

  const [patchService] = usePatchServiceMutation();
  const [serviceDelete, { isLoading: deleteLoading }] =
    useDeleteServiceMutation();

  const { data: totalService } = useGetProjectTotServiceQuery({
    id: +id!,
    params: {
      dateExpiryFilter: filtersService.dateExpiryFilter,
    },
  });

  const { data: totalServiceSh } = useGetProjectTotServiceShQuery({
    id: +id!,
    params: {
      //   dateExpiryFilter: filtersServiceSh.dateExpiryFilter,
    },
  });

  const serviceClientRenewMarkupTotal = useMemo(
    () =>
      totalService && totalService.renewPriceMarkup !== null
        ? numberToCurrency(totalService.renewPriceMarkup)
        : 0,
    [totalService],
  );

  const serviceShClientRenewMarkupTotal = useMemo(
    () =>
      totalServiceSh && totalServiceSh.renewPriceMarkup !== null
        ? numberToCurrency(totalServiceSh.renewPriceMarkup)
        : 0,
    [totalServiceSh],
  );

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const changeServiceShToNotify = async (
    serviceId: number,
    toNotify: boolean,
  ) => {
    try {
      await patchServiceSh({
        id: serviceId,
        body: { projectsToNotify: [{ projectId: +id!, toNotify: toNotify }] },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const editServiceSh = async (serviceSh: ServiceSh) => {
    openModal({
      title: 'Modifica servizio',
      children: (
        <ModalPatchServiceSh
          serviceSh={serviceSh}
          setDateExpirySupplier={true} // TODO: cos'è sta roba?
        />
      ),
    });
  };

  const deleteServiceSh = async (serviceSh: ServiceSh) => {
    openConfirmModal({
      title: 'Eliminazione servizio',
      size: 'lg',
      children: (
        <Text>
          Stai per eliminare il servizio {serviceSh.name}. Sicuro di voler
          continuare?
        </Text>
      ),
      labels: {
        confirm: 'Conferma eliminazione',
        cancel: 'Annulla',
      },
      confirmProps: { color: '#c00404' },
      onConfirm: async () => {
        try {
          await serviceShDelete({
            id: serviceSh.id,
          }).unwrap();
          showNotification({
            title: 'Servizio eliminato',
            message: "L'eliminazione del servizio è avvenuta con successo",
          });
        } catch (e) {
          console.error(e);
          if (isApiError(e)) {
            showNotification({
              color: 'red',
              title: 'Errore',
              message: `${e.data.message}`,
            });
          }
        }
      },
    });
  };

  const changeServiceToNotify = async (
    serviceId: number,
    toNotify: boolean,
  ) => {
    try {
      await patchService({
        id: serviceId,
        body: { projectsToNotify: [{ projectId: +id!, toNotify: toNotify }] },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile attivare il servizio',
      });
    }
  };

  const editService = async (service: Service) => {
    openModal({
      title: 'Modifica servizio',
      children: (
        <ModalPatchService
          service={service}
          setDateExpirySupplier={true} // TODO: cos'è sta roba?
        />
      ),
    });
  };

  const deleteService = async (service: Service) => {
    openConfirmModal({
      title: 'Eliminazione servizio',
      size: 'lg',
      children: (
        <Text>
          Stai per eliminare il servizio {service.name}. Sicuro di voler
          continuare?
        </Text>
      ),
      labels: {
        confirm: 'Conferma eliminazione',
        cancel: 'Annulla',
      },
      confirmProps: { color: '#c00404' },
      onConfirm: async () => {
        try {
          await serviceDelete({
            id: service.id,
          }).unwrap();
          showNotification({
            title: 'Servizio eliminato',
            message: "L'eliminazione del servizio è avvenuta con successo",
          });
        } catch (e) {
          console.error(e);
          if (isApiError(e)) {
            showNotification({
              color: 'red',
              title: 'Errore',
              message: `${e.data.message}`,
            });
          }
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  useLayoutProps({
    title: `Servizi del progetto: ${project?.name}`,
    loadingText: isLoadingProform ? 'Generazione proforma...' : undefined,
  });

  return (
    <>
      <Group>
        <Group mb="md">
          <Paper p="xl" mb="md" className={classes.info_paper}>
            <Text mb="sm" fz="lg">
              Totale costi rinnovi cliente
            </Text>
            <Group gap={rem('4rem')}>
              <Group align="start">
                <IconCategory2 size="1.7rem" color="#abcdcd" />
                <div>
                  <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                    € {serviceShClientRenewMarkupTotal}
                  </Text>
                  <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                    Servizi Shellrent
                  </Text>
                </div>
              </Group>
              <Group>
                <Group align="start">
                  <IconCategory size="1.7rem" color="#abcdcd" />
                  <div>
                    <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                      € {serviceClientRenewMarkupTotal}
                    </Text>
                    <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                      Servizi
                    </Text>
                  </div>
                </Group>
              </Group>
            </Group>
          </Paper>
          <Paper
            p="xl"
            mb="md"
            className={(classes.info_paper, classes.info_paper_btn)}
            onClick={async () => {
              try {
                const blob = await getProform(id!).unwrap();

                const alink = document.createElement('a');
                alink.href = window.URL.createObjectURL(blob);
                alink.download = `Proforma ${project?.name}.pdf`;
                alink.click();
                alink.remove();
              } catch (e) {
                console.error(e);
                showNotification({
                  title: 'Errore',
                  message: 'Errore nella generazione della proforma',
                  color: 'red',
                });
              }
            }}
          >
            <Group align="center">
              <IconFileText size="1.7rem" color="#fff" />
              <div>
                <Text fw={700}>Scarica proforma</Text>
              </div>
            </Group>
          </Paper>
        </Group>
      </Group>

      <SearchAddContainer
        title={`Servizi Shellrent (${shCount.count})`}
        additionalFilters={
          <Select
            value={filtersServiceSh.dateExpiryFilter}
            onChange={(value) => {
              const servicesShDateExpiryFilterSet = value as DateExpiryFilter;
              searchParams.set(
                'dateExpiryShFilter',
                servicesShDateExpiryFilterSet,
              );
              setSearchParams(searchParams);

              setFiltersServiceSh({
                ...filtersServiceSh,
                dateExpiryFilter: servicesShDateExpiryFilterSet,
              });
            }}
            placeholder="Filtro scadenza"
            data={[
              { label: 'Tutti i servizi', value: 'all' },
              { label: 'Servizi scaduti', value: 'expired' },
              { label: 'Servizi non scaduti', value: 'notExpired' },
              { label: 'Servizi in scadenza', value: 'almostExpired' },
            ]}
            allowDeselect={false}
          />
        }
        searchPlaceholder="Ricerca per nome"
        searchValue={filtersServiceSh.searchQuery}
        onSearchChange={(s) =>
          setFiltersServiceSh({ ...filtersServiceSh, searchQuery: s })
        }
      >
        <StandardDataTable
          records={servicesSh}
          columns={[
            {
              accessor: 'toNotify',
              title: 'Da notificare',
              width: '0%',
              render: (record) => {
                const project = record.projects.find(
                  (p) => p.projectId === +id!,
                );

                return (
                  <Switch
                    checked={project?.toNotify}
                    onChange={(e) =>
                      changeServiceShToNotify(
                        record.id,
                        e.currentTarget.checked,
                      )
                    }
                    style={{ justifyContent: 'center' }}
                  />
                );
              },
            },
            {
              accessor: 'shellrentId',
              title: 'Id Sh',
              width: '0%',
              sortable: true,
            },
            {
              accessor: 'name',
              title: 'Nome',
              sortable: true,
              render: (record) => (
                <Group justify="space-between" wrap="nowrap">
                  {record.name}
                  {record.secondaryServicesMapped &&
                    record.secondaryServicesMapped.length > 0 && (
                      <IconCircleDashedNumber2 />
                    )}
                </Group>
              ),
            },
            {
              accessor: 'renewPrice',
              title: 'Costo rinnovo fornitore (anno)',
              width: '0%',
              render: (record) => (
                <>
                  €{' '}
                  {numberToCurrency(
                    getYearlyPriceFromRecurrence(
                      record.renewPrice,
                      record.renewRecurrence,
                    ),
                  )}
                </>
              ),
            },
            {
              accessor: 'renewPriceMarkup',
              title: 'Costo rinnovo cliente (anno)',
              width: '0%',
              render: (record) => {
                const project = record.projects.find(
                  (p) => p.projectId === +id!,
                );

                return (
                  <Group justify="space-between">
                    €{' '}
                    {numberToCurrency(
                      getYearlyPriceFromRecurrence(
                        project?.renewPriceMarkup || 0,
                        project?.renewRecurrence || 'Y1',
                      ),
                    )}
                    {project?.toPay || false ? (
                      <Badge variant="filled" color="#c00404" radius="sm">
                        Non pagato
                      </Badge>
                    ) : (
                      <Badge variant="filled" color="green" radius="sm">
                        Pagato
                      </Badge>
                    )}
                  </Group>
                );
              },
            },
            {
              accessor: 'dateExpiry',
              title: 'Data rinnovo cliente',
              width: '0%',
              sortable: true,
              render: (record) => {
                const project = record.projects.find(
                  (p) => p.projectId === +id!,
                );

                return (
                  <ExpireDateWithTooltip
                    date={project ? new Date(project.dateExpiry) : new Date()}
                  />
                );
              },
            },
            {
              accessor: 'actions',
              title: '',
              width: '0%',
              render: (record) => (
                <Group justify="flex-end" wrap="nowrap">
                  <Tooltip label="Modifica">
                    <ActionIcon onClick={() => editServiceSh(record)}>
                      <IconPencil />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Elimina">
                    <ActionIcon
                      loading={deleteShLoading}
                      onClick={() => deleteServiceSh(record)}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              ),
            },
          ]}
          rowExpansion={{
            allowMultiple: true,
            expandable: ({ record }) =>
              !!(
                record.secondaryServicesMapped &&
                record.secondaryServicesMapped.length > 0
              ),
            content: ({ record }) => (
              <Container p="md" size="100%">
                <StandardDataTable
                  records={record.secondaryServicesMapped!}
                  columns={[
                    {
                      accessor: 'toNotify',
                      title: 'Da notificare',
                      width: '0%',
                      render: (record) => {
                        const project = record.projects.find(
                          (p) => p.projectId === +id!,
                        );

                        return (
                          <Switch
                            checked={project?.toNotify}
                            onChange={(e) =>
                              changeServiceShToNotify(
                                record.id,
                                e.currentTarget.checked,
                              )
                            }
                            style={{ justifyContent: 'center' }}
                          />
                        );
                      },
                    },
                    {
                      accessor: 'shellrentId',
                      title: 'Id Sh',
                      width: '0%',
                    },
                    {
                      accessor: 'name',
                      title: 'Nome',
                    },
                    {
                      accessor: 'renewPrice',
                      title: 'Costo rinnovo fornitore (anno)',
                      width: '0%',
                      render: (record) => (
                        <>
                          €{' '}
                          {numberToCurrency(
                            getYearlyPriceFromRecurrence(
                              record.renewPrice,
                              record.renewRecurrence,
                            ),
                          )}
                        </>
                      ),
                    },
                    {
                      accessor: 'renewPriceMarkup',
                      title: 'Costo rinnovo cliente (anno)',
                      width: '0%',
                      render: (record) => {
                        const project = record.projects.find(
                          (p) => p.projectId === +id!,
                        );

                        return (
                          <Group justify="space-between">
                            €{' '}
                            {numberToCurrency(
                              getYearlyPriceFromRecurrence(
                                project?.renewPriceMarkup || 0,
                                project?.renewRecurrence || 'Y1',
                              ),
                            )}
                            {project?.toPay || false ? (
                              <Badge
                                variant="filled"
                                color="#c00404"
                                radius="sm"
                              >
                                Non pagato
                              </Badge>
                            ) : (
                              <Badge variant="filled" color="green" radius="sm">
                                Pagato
                              </Badge>
                            )}
                          </Group>
                        );
                      },
                    },
                    {
                      accessor: 'dateExpiry',
                      title: 'Data rinnovo cliente',
                      width: '0%',
                      render: (record) => {
                        const project = record.projects.find(
                          (p) => p.projectId === +id!,
                        );

                        return (
                          <ExpireDateWithTooltip
                            date={
                              project
                                ? new Date(project.dateExpiry)
                                : new Date()
                            }
                          />
                        );
                      },
                    },
                    {
                      accessor: 'actions',
                      title: '',
                      width: '0%',
                      render: (record) => (
                        <Group justify="flex-end" wrap="nowrap">
                          <Tooltip label="Modifica">
                            <ActionIcon onClick={() => editServiceSh(record)}>
                              <IconPencil />
                            </ActionIcon>
                          </Tooltip>
                          <Tooltip label="Elimina">
                            <ActionIcon
                              loading={deleteShLoading}
                              onClick={() => deleteServiceSh(record)}
                            >
                              <IconTrash />
                            </ActionIcon>
                          </Tooltip>
                        </Group>
                      ),
                    },
                  ]}
                />
              </Container>
            ),
          }}
          sortStatus={sortStatusServiceSh}
          onSortStatusChange={setSortStatusServiceSh}
          fetching={isLoadingSh}
          minHeight={servicesSh.length === 0 ? '7rem' : undefined}
          noRecordsText={
            errorServicesSh
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun servizio trovato'
          }
        />
        {servicesSh.length > 0 && (
          <PaginationRow
            searchParamsKey="Sh"
            pageLength={filtersServiceSh.pageLength || 1}
            page={filtersServiceSh.page || 1}
            totalPages={Math.ceil(
              shCount.count / (filtersServiceSh.pageLength || 1),
            )}
            onPageChange={(newPage) =>
              setFiltersServiceSh({ ...filtersServiceSh, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFiltersServiceSh({
                ...filtersServiceSh,
                pageLength: newPageLength,
                page: 1,
              })
            }
          />
        )}
      </SearchAddContainer>

      <Space h="xl" />

      <SearchAddContainer
        title={`Servizi (${servicesCount.count})`}
        additionalFilters={
          <Select
            value={filtersService.dateExpiryFilter}
            onChange={(value) => {
              const servicesDateExpiryFilterSet = value as DateExpiryFilter;
              searchParams.set('dateExpiryFilter', servicesDateExpiryFilterSet);
              setSearchParams(searchParams);

              setFiltersService({
                ...filtersService,
                dateExpiryFilter: servicesDateExpiryFilterSet,
              });
            }}
            placeholder="Filtro scadenza"
            data={[
              { label: 'Tutti i servizi', value: 'all' },
              { label: 'Servizi scaduti', value: 'expired' },
              { label: 'Servizi non scaduti', value: 'notExpired' },
              { label: 'Servizi in scadenza', value: 'almostExpired' },
            ]}
            allowDeselect={false}
          />
        }
        searchPlaceholder="Ricerca per nome"
        searchValue={filtersService.searchQuery}
        onSearchChange={(s) =>
          setFiltersService({ ...filtersService, searchQuery: s })
        }
      >
        <StandardDataTable
          records={services}
          columns={[
            {
              accessor: 'toNotify',
              title: 'Da notificare',
              width: '0%',
              render: (record) => {
                const project = record.projects.find(
                  (p) => p.projectId === +id!,
                );

                return (
                  <Switch
                    checked={project?.toNotify}
                    onChange={(e) =>
                      changeServiceToNotify(record.id, e.currentTarget.checked)
                    }
                    style={{ justifyContent: 'center' }}
                  />
                );
              },
            },
            {
              accessor: 'name',
              title: 'Nome',
              sortable: true,
            },
            {
              accessor: 'renewPrice',
              title: 'Costo rinnovo fornitore (anno)',
              width: '0%',
              render: (record) => (
                <>
                  €{' '}
                  {numberToCurrency(
                    getYearlyPriceFromRecurrence(
                      record.renewPrice,
                      record.renewRecurrence,
                    ),
                  )}
                </>
              ),
            },
            {
              accessor: 'renewPriceMarkup',
              title: 'Costo rinnovo cliente (anno)',
              width: '0%',
              render: (record) => {
                const project = record.projects.find(
                  (p) => p.projectId === +id!,
                );

                return (
                  <Group justify="space-between">
                    €{' '}
                    {numberToCurrency(
                      getYearlyPriceFromRecurrence(
                        project?.renewPriceMarkup || 0,
                        project?.renewRecurrence || 'Y1',
                      ),
                    )}
                    {project?.toPay || false ? (
                      <Badge variant="filled" color="#c00404" radius="sm">
                        Non pagato
                      </Badge>
                    ) : (
                      <Badge variant="filled" color="green" radius="sm">
                        Pagato
                      </Badge>
                    )}
                  </Group>
                );
              },
            },
            {
              accessor: 'dateExpiry',
              title: 'Data rinnovo cliente',
              width: '0%',
              sortable: true,
              render: (record) => {
                const project = record.projects.find(
                  (p) => p.projectId === +id!,
                );

                return (
                  <ExpireDateWithTooltip
                    date={project ? new Date(project.dateExpiry) : new Date()}
                  />
                );
              },
            },
            {
              accessor: 'actions',
              title: '',
              width: '0%',
              render: (record) => (
                <Group justify="flex-end" wrap="nowrap">
                  <Tooltip label="Modifica">
                    <ActionIcon onClick={() => editService(record)}>
                      <IconPencil />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Elimina">
                    <ActionIcon
                      loading={deleteLoading}
                      onClick={() => deleteService(record)}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              ),
            },
          ]}
          sortStatus={sortStatusService}
          onSortStatusChange={setSortStatusService}
          fetching={isLoadingServices}
          minHeight={services.length === 0 ? '7rem' : undefined}
          noRecordsText={
            errorServices
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun servizio trovato'
          }
        />
        {services.length > 0 && (
          <PaginationRow
            pageLength={filtersService.pageLength || 1}
            page={filtersService.page || 1}
            totalPages={Math.ceil(
              servicesCount.count / (filtersService.pageLength || 1),
            )}
            onPageChange={(newPage) =>
              setFiltersService({ ...filtersService, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFiltersService({
                ...filtersService,
                pageLength: newPageLength,
                page: 1,
              })
            }
          />
        )}
      </SearchAddContainer>
    </>
  );
};

export default ProjectServices;
