import { FC, useState } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Select, Switch, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { Project, usePatchProjectMutation } from '@api/projects/projects.api';

interface ModalPatchProjectProps extends ModalProps {
  project: Project;
}

const ModalPatchProject: FC<ModalPatchProjectProps> = ({
  project,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [patchProject, { isLoading }] = usePatchProjectMutation();

  // ==========================================================================
  // State
  // ==========================================================================
  const [completed, setcompleted] = useState(project.completed);

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: project.name,
    clientId: project.client.id.toString(),
    projectsCategory: project.projectsCategory || null,
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patchProject({
        id: project.id,
        body: { ...values, clientId: +values.clientId, completed },
      }).unwrap();

      showNotification({
        title: 'Progetto modificato',
        message: 'Il progetto è stato modificato con successo',
      });

      onConfirmed?.();
      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput label="Nome:" required {...form.getInputProps('name')} />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            color="default"
            required
            searchable
            label="Cliente:"
            style={{
              '&::selected': {
                backgroundColor: 'red',
              },
            }}
            data={
              project
                ? [
                    {
                      value: project.client.id.toString(),
                      label:
                        project.client.type === 'company'
                          ? project.client.companyName
                          : project.client.name + ' ' + project.client.surname,
                    },
                  ]
                : []
            }
            {...form.getInputProps('clientId')}
            allowDeselect={false}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            color="default"
            searchable
            label="Categoria:"
            style={{
              '&::selected': {
                backgroundColor: 'red',
              },
            }}
            data={[
              { value: 'app', label: 'App' },
              { value: 'service', label: 'Servizio' },
              { value: 'website', label: 'Sito web' },
            ]}
            {...form.getInputProps('projectsCategory')}
            allowDeselect={false}
          />
        </Grid.Col>
        {/* ROW */}
        <Grid.Col span={12}>
          <Switch
            label="Completato"
            checked={completed}
            onChange={(e) => setcompleted(e.currentTarget.checked)}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={9}>
          {form.errors.general && (
            <Text c="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={3}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalPatchProject;
