import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/carousel/styles.css';
import 'mantine-datatable/styles.css';
import './styles/global.css';

import { generateColors } from '@mantine/colors-generator';
import { MantineProvider, rem } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import useTheme from '@hooks/useTheme';

import theme from '@styles/theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const { primaryColor } = useTheme();

  return (
    <>
      <MantineProvider
        theme={{
          ...theme,
          colors: { ...theme.colors, brand: generateColors(primaryColor) },
        }}
        defaultColorScheme="auto"
      >
        <Notifications position="top-center" autoClose={5000} />
        <ModalsProvider
          modalProps={{
            size: rem('80rem'),
            overlayProps: {
              color: 'black',
              opacity: '0.8',
            },
          }}
        >
          {children}
        </ModalsProvider>
      </MantineProvider>
    </>
  );
}
