import { FC, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import {
  Alert,
  Box,
  Burger,
  Button,
  Container,
  Drawer,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconAlertCircle, IconChevronLeft } from '@tabler/icons-react';

import Sidebar from '@components/sidebar/Sidebar';

import classes from './Layout.module.css';

const Layout: FC = () => {
  const [opened, setOpened] = useState(false);

  const isSmallerThanMd = useMediaQuery('(max-width: 768px)');

  // Context
  const [title, setTitle] = useState<string>('');
  const [backLink, setBackLink] = useState<
    | {
        title: string;
        to: string;
      }
    | undefined
  >(undefined);

  // Update banner state to an array of banners
  const [banners, setBanners] = useState<{ title: string; content: string }[]>(
    [],
  );

  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);

  return (
    <div
      className={classes.shell}
      style={{ display: isSmallerThanMd ? 'block' : 'grid' }}
    >
      <Box visibleFrom="md">
        <Sidebar />
      </Box>

      <Box hiddenFrom="md">
        <Drawer
          color="#222222"
          opened={opened}
          onClose={() => setOpened(false)}
          style={{
            backgroundColor: '#222222',
            border: 'none',
            header: {
              marginBottom: 0,
            },
          }}
        >
          <Sidebar />
        </Drawer>
      </Box>

      <div className={classes.container}>
        <Container size={1500} px="xl" pos="relative">
          <Group justify="space-between">
            <Group>
              <Burger
                hiddenFrom="md"
                opened={opened}
                p={0}
                onClick={() => setOpened((o) => !o)}
              />

              <Group>
                {backLink?.title && (
                  <Button
                    component={Link}
                    to={backLink.to}
                    leftSection={<IconChevronLeft />}
                  >
                    {backLink.title}
                  </Button>
                )}
                {title && <Title order={1}>{title}</Title>}
              </Group>
            </Group>
            {/* <Group justify="space-between">
              <Tooltip label="Notifiche">
                <Link
                  to="/notifiche"
                  style={{ position: 'relative', display: 'inline-block' }}
                >
                  {notificationsCount.count !== 0 && (
                    <Badge
                      variant="filled"
                      size="sm"
                      style={{
                        position: 'absolute',
                        top: -5,
                        left: -5,
                      }}
                    >
                      {notificationsCount.count}
                    </Badge>
                  )}

                  <IconBell
                    color={colorScheme === 'light' ? '#222222' : '#C1C2C5'}
                  />
                </Link>
              </Tooltip>
               <Tooltip
                label={colorScheme === 'light' ? 'Dark mode' : 'Light mode'}
              >
                <ActionIcon onClick={() => toggleColorScheme()} mt="-5px">
                  {colorScheme === 'light' ? (
                    <IconMoonStars color="#222222" />
                  ) : (
                    <IconSunHigh color="#C1C2C5" />
                  )}
                </ActionIcon>
              </Tooltip> 
            </Group> */}
          </Group>
        </Container>
        <Container size={1500} p="xl" pos="relative">
          <Stack align="center">
            {loadingText && <Text>{loadingText}</Text>}
          </Stack>

          {banners &&
            banners.length > 0 &&
            banners.map((banner, index) => {
              if (banner && banner.title && banner.content) {
                return (
                  <Alert
                    key={index}
                    color="red"
                    icon={<IconAlertCircle />}
                    title={banner.title}
                    mb="md"
                  >
                    {banner.content}
                  </Alert>
                );
              }
              return null;
            })}

          <Outlet
            context={{
              setTitle,
              setBackLink,
              setBanners, // Updated to setBanners
              setLoadingText,
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default Layout;
