import { FC, useEffect } from 'react';

import { ModalProps } from '@interfaces/modals.interface';
import { Button, Grid, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { handleSubmitError } from '@utils/general';

import { useGetClientsQuery } from '@api/clients/clients.api';
import { usePostAddProjectMutation } from '@api/projects/projects.api';

interface ModalAddProjectProps extends ModalProps {
  initialProjectName?: string;
}

const ModalAddProject: FC<ModalAddProjectProps> = ({
  initialProjectName,
  closeAllOnConfirm = true,
  onConfirmed,
}) => {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [data, { isLoading }] = usePostAddProjectMutation();

  const { data: clientData = [] } = useGetClientsQuery({}); // TODO: client selector

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    name: initialProjectName || '',
    clientId: '',
    projectsCategory: null,
  };

  const form = useForm({
    initialValues,
  });

  useEffect(() => {
    if (initialProjectName && initialProjectName.trim() !== '') {
      form.setFieldValue('name', initialProjectName);
    }
  }, [initialProjectName]);

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await data({ ...values, clientId: +values.clientId }).unwrap();

      showNotification({
        title: 'Progetto aggiunto',
        message: 'Il progetto è stato aggiunto con successo',
      });

      onConfirmed?.();
      if (closeAllOnConfirm) {
        closeAllModals();
      }
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ROW */}
        <Grid.Col span={12}>
          <TextInput
            label="Nome:"
            maxLength={70}
            required
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            color="default"
            required
            searchable
            label="Cliente:"
            style={{
              '&::selected': {
                backgroundColor: 'red',
              },
            }}
            data={
              clientData
                ? clientData.map((client) => ({
                    value: client.id.toString(),
                    label:
                      client.type === 'company'
                        ? client.companyName
                        : client.name + ' ' + client.surname,
                  }))
                : []
            }
            {...form.getInputProps('clientId')}
            allowDeselect={false}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            color="default"
            searchable
            label="Categoria:"
            style={{
              '&::selected': {
                backgroundColor: 'red',
              },
            }}
            data={[
              { value: 'app', label: 'App' },
              { value: 'service', label: 'Servizio' },
              { value: 'website', label: 'Sito web' },
            ]}
            {...form.getInputProps('projectsCategory')}
            allowDeselect={false}
          />
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={10}>
          {form.errors.general && (
            <Text color="red" mt="md">
              {form.errors.general}
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <Button type="submit" fullWidth mt="md" loading={isLoading}>
            Conferma
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default ModalAddProject;
