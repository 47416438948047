import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import { Carousel } from '@mantine/carousel';
import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  Overlay,
  Paper,
  rem,
  ScrollArea,
  Skeleton,
  Text,
  Title,
} from '@mantine/core';
import {
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconCategory,
  IconCategory2,
  IconFileText,
  IconHourglassEmpty,
  IconHourglassLow,
  IconUser,
} from '@tabler/icons-react';
import { monthsArrray } from '@utils/date';
import { capitalizeFirstLetter } from '@utils/general';
import { numberToCurrency } from '@utils/number';

import { useGetClientsCountQuery } from '@api/clients/clients.api';
import {
  useGetMonthlyProjectFinancesQuery,
  useGetProjectCountQuery,
} from '@api/projects/projects.api';
import { useGetTotalServiceQuery } from '@api/services/services.api';
import { useGetTotalServiceShQuery } from '@api/servicessh/servicesSh.api';
import { useGetTotalPriceHistoryQuery } from '@api/statistics/statistics.api';

import useLayoutProps from '@components/layout/useLayoutProps';

import classes from './Dashboard.module.css';

export default function Home() {
  // ==========================================================================
  // General
  // ==========================================================================
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    ArcElement,
  );

  Chart.defaults.font = { family: 'Montserrat, sans-serif', size: 12 };

  useLayoutProps({
    title: 'Dashboard',
  });
  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: totalservice,
    isLoading: serviceLoading,
    isError: errorService,
  } = useGetTotalServiceQuery();

  const {
    data: totalserviceSh,
    isLoading: serviceShLoading,
    isError: errorServiceSh,
  } = useGetTotalServiceShQuery();

  const {
    data: totalPriceHistory = {},
    isLoading: priceHistoryLoading,
    isError: errorPriceHistory,
  } = useGetTotalPriceHistoryQuery();

  const {
    data: getMonthlyProjectFinances = [],
    isLoading: monthlyProjectFinancesLoading,
  } = useGetMonthlyProjectFinancesQuery();

  const { data: clientsCount, isLoading: isLoadingClientsCount } =
    useGetClientsCountQuery(undefined);

  const { data: projectsCount, isLoading: isLoadingProjectsCount } =
    useGetProjectCountQuery(undefined);

  const totalCostData = Object.keys(totalPriceHistory).map((key) =>
    totalPriceHistory[+key] ? totalPriceHistory[+key]!.totalCost : null,
  );

  const totalRevenueData = Object.keys(totalPriceHistory).map((key) =>
    totalPriceHistory[+key] ? totalPriceHistory[+key]!.totalRevenue : null,
  );

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Group>
        <Skeleton
          visible={
            serviceLoading ||
            serviceShLoading ||
            isLoadingClientsCount ||
            isLoadingProjectsCount
          }
          styles={{ root: { width: 'auto', flex: 3 } }}
        >
          <Paper p="xl" className={classes.info_paper}>
            <Group gap={rem('4rem')}>
              <Group align="start">
                <IconCategory size="1.7rem" color="#abcdcd" />
                <div>
                  <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                    {totalservice?.totalCount}
                  </Text>
                  <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                    Servizi
                  </Text>
                </div>
              </Group>
              <Group align="start">
                <IconCategory2 size="1.7rem" color="#abcdcd" />
                <div>
                  <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                    {totalserviceSh?.totalCount}
                  </Text>
                  <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                    Servizi Shellrent
                  </Text>
                </div>
              </Group>
              <Group align="start">
                <IconUser size="1.7rem" color="#abcdcd" />
                <div>
                  <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                    {clientsCount?.count}
                  </Text>
                  <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                    Clienti
                  </Text>
                </div>
              </Group>
              <Group align="start">
                <IconFileText size="1.7rem" color="#abcdcd" />
                <div>
                  <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                    {projectsCount?.count}
                  </Text>
                  <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                    Progetti
                  </Text>
                </div>
              </Group>
            </Group>
          </Paper>
        </Skeleton>

        <Skeleton
          visible={serviceLoading || serviceShLoading}
          styles={{ root: { width: 'auto', flex: 1 } }}
        >
          <Paper
            p="xl"
            className={classes.info_paper}
            style={{
              backgroundColor:
                (totalservice?.expiredCount || 0) +
                  (totalserviceSh?.expiredCount || 0) !==
                0
                  ? '#a12828'
                  : undefined,
            }}
            component={Link}
            to={'/servizi?dateExpiryShFilter=expired&dateExpiryFilter=expired'}
          >
            <Group align="start">
              <IconHourglassEmpty size="1.7rem" color="#fff" />
              <div>
                <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700} c="white">
                  {(totalservice?.expiredCount || 0) +
                    (totalserviceSh?.expiredCount || 0)}
                </Text>
                <Text size="sm" mt={rem('0.3rem')} c="white">
                  Servizi scaduti
                </Text>
              </div>
            </Group>
          </Paper>
        </Skeleton>

        <Skeleton
          visible={serviceLoading || serviceShLoading}
          styles={{ root: { width: 'auto', flex: 1 } }}
        >
          <Paper
            p="xl"
            className={classes.info_paper}
            style={{
              backgroundColor:
                (totalservice?.almostExpiredCount || 0) +
                  (totalserviceSh?.almostExpiredCount || 0) !==
                0
                  ? '#cc8a21'
                  : undefined,
            }}
            component={Link}
            to={
              '/servizi?dateExpiryShFilter=almostExpired&dateExpiryFilter=almostExpired'
            }
          >
            <Group align="start">
              <IconHourglassLow size="1.7rem" color="#fff" />
              <div>
                <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700} c="white">
                  {(totalservice?.almostExpiredCount || 0) +
                    (totalserviceSh?.almostExpiredCount || 0)}
                </Text>
                <Text size="sm" mt={rem('0.3rem')} c="white">
                  Servizi in scadenza
                </Text>
              </div>
            </Group>
          </Paper>
        </Skeleton>
      </Group>

      <Box mt={rem('3rem')}>
        <Title order={3} mb="md">
          Rinnovi clienti
        </Title>
        <Skeleton
          visible={monthlyProjectFinancesLoading}
          styles={{ root: { width: 'auto', height: '50vh' } }}
        >
          <Carousel
            slideSize="33.333333%"
            slideGap="md"
            slidesToScroll={3}
            h="50vh"
            align="start"
            controlsOffset="xs"
            controlSize={25}
            draggable={false}
            classNames={classes}
          >
            {getMonthlyProjectFinances.map((f) => {
              const currentDate = new Date();
              const currentMonth = currentDate
                .toLocaleString('default', { month: 'long' })
                .toLowerCase();

              return (
                <Carousel.Slide>
                  <Container p="md" className={classes.cardCarousel}>
                    <Box>
                      <Flex justify="space-between">
                        <Title order={3}>
                          {capitalizeFirstLetter(f.month)}
                        </Title>
                        {f.month.split(' ')[0] === currentMonth && (
                          <Badge variant="light" radius="md">
                            Corrente
                          </Badge>
                        )}
                      </Flex>
                      <Box>
                        <Divider my="md" fw={600} />
                        <ScrollArea h="35vh">
                          <Flex
                            direction="column"
                            className={classes.cardCarouselProjects}
                          >
                            {f.data.length === 0 && (
                              <Text fs="italic">Nessun progetto</Text>
                            )}
                            {f.data.map((d) => {
                              return (
                                <Link
                                  to={`progetto/${d.id}/servizi`}
                                  className={classes.boxProjectInfo}
                                >
                                  {d.name}
                                  <Text fs="italic" c="dimmed" fz="xs">
                                    {d.client.type === 'holder'
                                      ? d.client.name + ' ' + d.client.surname
                                      : d.client.companyName}
                                  </Text>
                                </Link>
                              );
                            })}
                          </Flex>
                        </ScrollArea>
                      </Box>
                    </Box>
                    <div>
                      <Flex justify="space-between" w="100%">
                        <Text fw={600}>Totale rinnovi fornitore:</Text>
                        <Flex align="center">
                          <Text fw={400}>
                            € {numberToCurrency(f.miroirExpense)}
                          </Text>
                          <IconCaretDownFilled color="#a12828" />
                        </Flex>
                      </Flex>
                      <Flex justify="space-between">
                        <Text fw={600}>Totale rinnovo clienti:</Text>
                        <Flex align="center">
                          <Text fw={400}>
                            € {numberToCurrency(f.miroirIncome)}{' '}
                          </Text>
                          <IconCaretUpFilled color="#008000" />
                        </Flex>
                      </Flex>
                    </div>
                  </Container>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Skeleton>
      </Box>

      <Grid mt={rem('3rem')} styles={{ inner: { paddingBottom: rem('4rem') } }}>
        {/* ROW */}
        <Grid.Col span={6}>
          <Title order={3} mb="md">
            Totali costi rinnovi
          </Title>
          <Paper p="xl" className={classes.paper_chart}>
            {(serviceLoading ||
              serviceShLoading ||
              errorService ||
              errorServiceSh ||
              (totalservice &&
                totalserviceSh &&
                (totalservice.renewPriceSum === null ||
                  totalservice.renewPriceSum === 0) &&
                (totalserviceSh.renewPriceSum === null ||
                  totalserviceSh.renewPriceSum === 0))) && (
              <Overlay className={classes.paper_chart_overlay}>
                {serviceLoading || serviceShLoading ? (
                  <Loader />
                ) : errorService || errorServiceSh ? (
                  'Errore nel caricamento dei dati'
                ) : (
                  'Nessun dato registrato'
                )}
              </Overlay>
            )}
            <Group justify="center" gap="xl" pb={rem('0.4rem')}>
              <Group align="center" gap="xs">
                <IconCaretUpFilled color="#008000" />
                <Text>Totale costi rinnovi clienti</Text>
              </Group>
              <Group align="center" gap="xs">
                <IconCaretDownFilled color="#a12828" />
                <Text>Totale costi rinnovi fornitore</Text>
              </Group>
            </Group>
            <Bar
              options={{
                interaction: {
                  intersect: false,
                  mode: 'index',
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.dataset.label || '';
                        label += ': ';

                        if (context.parsed.y !== null) {
                          label += '€ ' + numberToCurrency(context.parsed.y);
                        }

                        return label;
                      },
                      footer: (tooltipItems) => {
                        const gain =
                          tooltipItems[0].parsed.y - tooltipItems[1].parsed.y;

                        return 'Guadagno: € ' + numberToCurrency(gain);
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (value) => `€ ${value}`,
                    },
                  },
                },
              }}
              data={{
                labels: ['Servizi Shellrent', 'Servizi'],
                datasets: [
                  {
                    label: 'Totale costi rinnovi clienti',
                    data: [
                      totalserviceSh?.renewPriceMarkupSum || 0,
                      totalservice?.renewPriceMarkupSum || 0,
                    ],
                    borderRadius: 5,
                    backgroundColor: '#008000',
                    hoverBackgroundColor: '#008000',
                  },
                  {
                    label: 'Totale costi rinnovi fornitore',
                    data: [
                      totalserviceSh?.renewPriceSum || 0,
                      totalservice?.renewPriceSum || 0,
                    ],
                    borderRadius: 5,
                    backgroundColor: '#a12828',
                    hoverBackgroundColor: '#a12828',
                  },
                ],
              }}
            />
          </Paper>
        </Grid.Col>

        {/* ROW */}
        <Grid.Col span={6}>
          <Title order={3} mb="md">
            Storico costi {new Date().getFullYear()}
          </Title>
          <Paper p="xl" className={classes.paper_chart}>
            {(priceHistoryLoading ||
              errorPriceHistory ||
              totalRevenueData.filter((v) => v !== null).length === 0 ||
              totalCostData.filter((v) => v !== null).length === 0) && (
              <Overlay className={classes.paper_chart_overlay}>
                {priceHistoryLoading ? (
                  <Loader />
                ) : errorPriceHistory ? (
                  'Errore nel caricamento dei dati'
                ) : (
                  'Nessun dato registrato'
                )}
              </Overlay>
            )}
            <Group justify="center" gap="xl" pb={rem('0.4rem')}>
              <Group align="center" gap="xs">
                <div
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#0077b6',
                    height: '15px',
                    width: '15px',
                  }}
                ></div>
                <Text>Totale rinnovi</Text>
              </Group>
              <Group align="center" gap="xs">
                <div
                  style={{
                    borderRadius: '50%',
                    backgroundColor: '#d8572a',
                    height: '15px',
                    width: '15px',
                  }}
                ></div>
                <Text>Totale ricarichi</Text>
              </Group>
            </Group>
            <Line
              options={{
                interaction: {
                  intersect: false,
                  mode: 'index',
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.dataset.label || '';
                        label += ': ';

                        if (context.parsed.y !== null) {
                          label += new Intl.NumberFormat('it-IT', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(context.parsed.y);
                        }

                        return label;
                      },
                      footer: (tooltipItems) => {
                        const gain =
                          tooltipItems[1].parsed.y - tooltipItems[0].parsed.y;

                        return (
                          'Guadagno: ' +
                          new Intl.NumberFormat('it-IT', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(gain)
                        );
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (value) => `€ ${value}`,
                    },
                  },
                },
              }}
              data={{
                labels: monthsArrray,
                datasets: [
                  {
                    label: 'Totale rinnovi',
                    data: totalCostData,
                    fill: false,
                    borderColor: '#0077b6',
                    backgroundColor: '#0077b6',
                    tension: 0.2,
                  },
                  {
                    label: 'Totale ricarichi',
                    data: totalRevenueData,
                    fill: false,
                    borderColor: '#d8572a',
                    backgroundColor: '#d8572a',
                    tension: 0.2,
                  },
                ],
              }}
            />
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
}
