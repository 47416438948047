import { SortOrder } from '@domain/types';

import api from '../';

interface ServiceCount {
  count: number;
}

export type SortBy = 'name' | 'dateExpiry';

export type DateExpiryFilter = 'all' | 'expired' | 'notExpired';

export interface ServiceApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
  dateExpiryFilter?: DateExpiryFilter;
}

export interface ServiceApiCountParams {
  searchQuery?: string;
  dateExpiryFilter?: DateExpiryFilter;
}

export interface ServiceProjectInfo {
  projectId: number;
  renewPriceMarkup: number;
  toPay: boolean;
  toNotify: boolean;
  dateExpiry: string;
  renewRecurrence: string;
  project?: { id: number; name: string; clientId: number };
}

export interface ProjectRenewPriceMarkups {
  projectId: number;
  renewPriceMarkup: number;
}

export interface ProjectsRenewRecurrence {
  projectId: number;
  renewRecurrence: string;
}

export interface ProjectToPay {
  projectId: number;
  toPay: boolean;
}

export interface ProjectToNotify {
  projectId: number;
  toNotify: boolean;
}

export interface ProjectDateExpiry {
  projectId: number;
  dateExpiry: Date | string;
}

export interface Service {
  id: number;
  name: string;
  proformNote: string | null;
  note: string | null;
  renewPrice: number;
  renewPriceMarkup: number;
  toPay: boolean;
  toNotify: boolean;
  dateExpiry: string;
  toRenew: boolean;
  projects: ServiceProjectInfo[];
  dataExpirySupplier?: string;
  renewRecurrence: string;
}

export interface ServiceAddRequest {
  name?: string;
  proformNote?: string;
  note?: string;
  renewPrice?: number;
  dateExpiry?: string;
  projectsIds?: number[];
  projectsRenewPriceMarkups?: ProjectRenewPriceMarkups[];
  projectsToPay?: ProjectToPay[];
  projectsToNotify?: ProjectToNotify[];
  projectsDateExpiry?: ProjectDateExpiry[];
  projectsRenewRecurrence?: ProjectsRenewRecurrence[];
}

export interface ServiceTotal {
  renewPrice: number;
  renewPriceMarkup: number;
}

export interface Paramstot {
  id: string;
}

interface ServicePatchRequest {
  id: number;
  body: {
    name?: string;
    proformNote?: string;
    note?: string;
    renewPrice?: number;
    toRenew?: boolean;
    dateExpiry?: string;
    renewRecurrence?: string;
    projectsIds?: number[];
    projectsRenewPriceMarkups?: ProjectRenewPriceMarkups[];
    projectsToPay?: ProjectToPay[];
    projectsRenewRecurrence?: ProjectsRenewRecurrence[];
    projectsToNotify?: ProjectToNotify[];
    projectsDateExpiry?: ProjectDateExpiry[];
  };
}

interface ServiceStatusPatchRequest {
  id: string;
  body: {
    status: string;
  };
}

interface ServiceDeleteRequest {
  id: number;
}

//project service
// export interface PServiceApiQueryParams {
//   sortBy?: SortBy;
//   sortOrder?: SortOrder;
//   searchQuery?: string;
//   page?: number;
//   pageLength?: number;
// }

export interface ProjectServiceApiQueryParams {
  id: string;
  params: ServiceApiQueryParams;
}

interface ProjectServiceCount {
  count: number;
}

export interface ProjectServiceCountApiQueryParams {
  id: string;
  params: ServiceApiCountParams;
}

//client service
export interface ClientServiceApiQueryParams {
  id: string;
  params: ServiceApiQueryParams;
}

interface ClientServiceCount {
  count: number;
}

export interface ClientServiceCountApiQueryParams {
  id: string;
  params: ServiceApiCountParams;
}

export interface ServiceApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

interface TotalService {
  totalCount: number;
  toRenew: number;
  toNotRenew: number;
  renewPriceSum: number;
  renewPriceMarkupSum: number;
  expiredCount: number;
  almostExpiredCount: number;
}

interface GetProjectTotServiceParams {
  id: number;
  params: { dateExpiryFilter?: DateExpiryFilter };
}

interface GetClientTotServiceParams {
  id: number;
  params: { dateExpiryFilter?: DateExpiryFilter };
}

const servicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<Service[], ServiceApiQueryParams>({
      query: (params) => ({
        url: 'services',
        params,
      }),
      providesTags: ['service'],
    }),
    getServicesCount: builder.query<ServiceCount, ServiceApiCountParams>({
      query: (params) => ({
        url: 'services/count',
        params: params,
      }),
      providesTags: ['service'],
    }),
    postAddService: builder.mutation<string, ServiceAddRequest>({
      //prima risposta
      query: (body) => ({
        url: 'services',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['service'],
    }),
    patchService: builder.mutation<Service, ServicePatchRequest>({
      //prima risposta
      query: ({ id, body }) => ({
        url: `services/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['service'],
    }),
    deleteService: builder.mutation<string, ServiceDeleteRequest>({
      query: ({ id }) => ({
        url: `services/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['service'],
    }),
    getClientTotService: builder.query<ServiceTotal, GetClientTotServiceParams>(
      {
        query: ({ id, params }) => ({
          url: `/clients/${id}/services/totals`,
          params,
        }),
        providesTags: ['service'],
      },
    ),
    getProjectTotService: builder.query<
      ServiceTotal,
      GetProjectTotServiceParams
    >({
      query: ({ id, params }) => ({
        url: `/projects/${id}/services/totals`,
        params,
      }),
      providesTags: ['service'],
    }),
    patchStatusService: builder.mutation<Service, ServiceStatusPatchRequest>({
      //prima risposta
      query: ({ id, body }) => ({
        url: `services/status/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['service'],
    }),

    //ProjectService=========================================
    getProjectServices: builder.query<Service[], ProjectServiceApiQueryParams>({
      query: ({ id, params }) => ({
        url: `/projects/${id}/services`,
        params,
      }),
      providesTags: ['service'],
    }),
    getProjectServicesCount: builder.query<
      ProjectServiceCount,
      ProjectServiceCountApiQueryParams
    >({
      query: ({ id, params }) => ({
        url: `/projects/${id}/services/count`,
        params: params,
      }),
      providesTags: ['service'],
    }),
    //Client Service=======================================
    getClientServices: builder.query<Service[], ClientServiceApiQueryParams>({
      query: ({ id, params }) => ({
        url: `clients/${id}/services`,
        params,
      }),
      providesTags: ['service'],
    }),
    getClientServicesCount: builder.query<
      ClientServiceCount,
      ClientServiceCountApiQueryParams
    >({
      query: ({ id, params }) => ({
        url: `clients/${id}/services/count`,
        params: params,
      }),
      providesTags: ['service'],
    }),

    //total==========================
    getTotalService: builder.query<TotalService, void>({
      query: () => ({
        url: `services/totals`,
      }),
      providesTags: ['service'],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServicesCountQuery,
  usePostAddServiceMutation,
  usePatchServiceMutation,
  usePatchStatusServiceMutation,
  useDeleteServiceMutation,
  useGetClientTotServiceQuery,
  useGetProjectServicesQuery,
  useGetProjectServicesCountQuery,
  useGetClientServicesQuery,
  useGetClientServicesCountQuery,
  useGetTotalServiceQuery,
  useGetProjectTotServiceQuery,
} = servicesApi;
