import { DataTableSortStatus } from 'mantine-datatable';
import { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Group, Paper, rem, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCategory, IconCategory2 } from '@tabler/icons-react';
import { numberToCurrency } from '@utils/number';

import { useGetSingleClientQuery } from '@api/clients/clients.api';
import {
  DateExpiryFilter,
  Service,
  ServiceApiQueryParams,
  SortBy,
  useGetClientServicesCountQuery,
  useGetClientServicesQuery,
  useGetClientTotServiceQuery,
} from '@api/services/services.api';
import { generateDataServices } from '@api/services/services.mock';
import {
  CustomServiceShMap,
  ServiceShApiQueryParams,
  SortBySh,
  useGetClientServicesShCountQuery,
  useGetClientServicesShQuery,
  useGetClientTotServiceShQuery,
} from '@api/servicessh/servicesSh.api';
import { generateDataServicessh } from '@api/servicessh/servicesSh.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ServicesTables from '@components/ServicesTables';

import classes from './ClientService.module.css';

const ClientServices: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================

  const [filtersServiceSh, setFiltersServiceSh] =
    useState<ServiceShApiQueryParams>({
      page: +(searchParams.get('page') || 1),
      pageLength: +(searchParams.get('pageLength') || 20),
      searchQuery: '',
      dateExpiryFilter: searchParams.get('dateExpiryShFilter')
        ? (searchParams.get('dateExpiryShFilter') as DateExpiryFilter)
        : undefined,
    });

  const [searchQuerySh] = useDebouncedValue(filtersServiceSh.searchQuery, 200, {
    leading: true,
  });

  const [filtersService, setFiltersService] = useState<ServiceApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 20),
    searchQuery: '',
    dateExpiryFilter: searchParams.get('dateExpiryFilter')
      ? (searchParams.get('dateExpiryFilter') as DateExpiryFilter)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filtersService.searchQuery, 200, {
    leading: true,
  });

  const [sortStatusService, setSortStatusService] = useState<
    DataTableSortStatus<Service>
  >({
    columnAccessor: 'name',
    direction: 'asc',
  });

  const [sortStatusServiceSh, setSortStatusServiceSh] = useState<
    DataTableSortStatus<CustomServiceShMap>
  >({
    columnAccessor: 'dateExpiry',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatusService) {
      setFiltersService((f: ServiceApiQueryParams) => ({
        ...f,
        sortBy: sortStatusService.columnAccessor as SortBy,
        sortOrder: sortStatusService.direction,
      }));
    }

    if (sortStatusServiceSh) {
      setFiltersServiceSh((f: ServiceShApiQueryParams) => ({
        ...f,
        sortBy: sortStatusServiceSh.columnAccessor as SortBySh,
        sortOrder: sortStatusServiceSh.direction,
      }));
    }
  }, [sortStatusService, sortStatusServiceSh]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: shCount = { count: 0 }, isLoading: isLoadingShCount } =
    useGetClientServicesShCountQuery({
      id: id!,
      params: {
        searchQuery: searchQuerySh,
        dateExpiryFilter: filtersServiceSh.dateExpiryFilter,
      },
    });

  const {
    data: servicesSh = generateDataServicessh(4),
    isLoading: isLoadingSh,
    error: errorServicesSh,
  } = useGetClientServicesShQuery({
    id: id!,
    params: {
      ...filtersServiceSh,
      searchQuery: searchQuerySh,
    },
  });

  const {
    data: servicesCount = { count: 0 },
    isLoading: isLoadingServicesCount,
  } = useGetClientServicesCountQuery({
    id: id!,
    params: {
      searchQuery: searchQuery,
      dateExpiryFilter: filtersService.dateExpiryFilter,
    },
  });

  const {
    data: services = generateDataServices(5),
    isLoading: isLoadingServices,
    error: errorServices,
  } = useGetClientServicesQuery({
    id: id!,
    params: {
      ...filtersService,
      searchQuery,
    },
  });

  const { data: client } = useGetSingleClientQuery(+id!);

  useLayoutProps({
    title:
      client?.type === 'company'
        ? `Servizi del cliente: ${client?.companyName}`
        : `
          Servizi del cliente: ${client?.name} ${client?.surname}
          `,
  });
  const { data: totalService, isLoading: isLoadingClientTotService } =
    useGetClientTotServiceQuery({
      id: +id!,
      params: { dateExpiryFilter: filtersService.dateExpiryFilter },
    });

  const { data: totalServiceSh, isLoading: isLoadingClientTotServiceSh } =
    useGetClientTotServiceShQuery({
      id: +id!,
      params: { dateExpiryFilter: filtersServiceSh.dateExpiryFilter },
    });

  // const rinnovoService =
  //   totalService && totalService.renewPrice !== null
  //     ? numberToCurrency(totalService.renewPrice)
  //     : 0;

  const miroirService =
    totalService && totalService.renewPriceMarkup !== null
      ? numberToCurrency(totalService.renewPriceMarkup)
      : 0;

  // const rinnovoServiceSh =
  //   totalServiceSh && totalServiceSh.renewPrice !== null
  //     ? numberToCurrency(totalServiceSh.renewPrice)
  //     : 0;

  const miroirServiceSh =
    totalServiceSh && totalServiceSh.renewPriceMarkup !== null
      ? numberToCurrency(totalServiceSh.renewPriceMarkup)
      : 0;

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Group mb="md">
        <Paper p="xl" mb="md" className={classes.info_paper}>
          <Text mb="sm" fz="lg">
            Totale costi rinnovi cliente
          </Text>
          <Group gap={rem('4rem')}>
            <Group align="start">
              <IconCategory2 size="1.7rem" color="#abcdcd" />
              <div>
                <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                  € {miroirServiceSh}
                </Text>
                <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                  Servizi Shellrent
                </Text>
              </div>
            </Group>
            <Group>
              <Group align="start">
                <IconCategory size="1.7rem" color="#abcdcd" />
                <div>
                  <Text fz={rem('2rem')} lh={rem('1.7rem')} fw={700}>
                    € {miroirService}
                  </Text>
                  <Text c="dimmed" size="sm" mt={rem('0.3rem')}>
                    Servizi
                  </Text>
                </div>
              </Group>
            </Group>
          </Group>
        </Paper>
      </Group>

      <ServicesTables
        servicesData={services as Service[]}
        servicesShData={servicesSh as CustomServiceShMap[]}
        servicesShPagination={{
          count: shCount.count,
          pageLength: filtersServiceSh.pageLength || 1,
          page: filtersServiceSh.page || 1,
          onPageChange: (newPage) =>
            setFiltersService({ ...filtersService, page: newPage }),
          onPageLengthChange: (newPageLength) =>
            setFiltersService({
              ...filtersService,
              pageLength: newPageLength,
              page: 1,
            }),
        }}
        servicesPagination={{
          count: servicesCount.count,
          pageLength: filtersService.pageLength || 1,
          page: filtersService.page || 1,
          onPageChange: (newPage) =>
            setFiltersServiceSh({ ...filtersServiceSh, page: newPage }),
          onPageLengthChange: (newPageLength) =>
            setFiltersServiceSh({
              ...filtersServiceSh,
              pageLength: newPageLength,
              page: 1,
            }),
        }}
        servicesSearchValue={filtersService.searchQuery}
        onServicesSearchValueChange={(newValue) =>
          setFiltersService({ ...filtersService, searchQuery: newValue })
        }
        servicesShSearchValue={filtersServiceSh.searchQuery}
        onServicesShSearchValueChange={(newValue) =>
          setFiltersServiceSh({ ...filtersServiceSh, searchQuery: newValue })
        }
        hideAddServicesButtons
        hideToPayColumn
        hideToRenewColumn
        hideFilterAssociatedFilter={false}
        hideToNotifyColumn
        servicesDateExpiryFilter={filtersService.dateExpiryFilter}
        onServicesDateExpiryFilterChange={(value) => {
          const servicesDateExpiryFilter = value as DateExpiryFilter;
          searchParams.set('dateExpiryFilter', servicesDateExpiryFilter);
          setSearchParams(searchParams);

          setFiltersService({
            ...filtersService,
            dateExpiryFilter: servicesDateExpiryFilter,
          });
        }}
        servicesShDateExpiryFilter={filtersServiceSh.dateExpiryFilter}
        onServicesShDateExpiryFilterChange={(value) => {
          const servicesShDateExpiryFilter = value as DateExpiryFilter;

          searchParams.set('dateExpiryShFilter', servicesShDateExpiryFilter);
          setSearchParams(searchParams);

          setFiltersServiceSh({
            ...filtersServiceSh,
            dateExpiryFilter: servicesShDateExpiryFilter,
          });
        }}
        loadingServicesBool={
          isLoadingClientTotService ||
          isLoadingServices ||
          isLoadingServicesCount
        }
        loadingServicesShBool={
          isLoadingClientTotServiceSh || isLoadingShCount || isLoadingSh
        }
        errorServices={errorServices !== undefined}
        errorServicesSh={errorServicesSh !== undefined}
        labelColumnRenewPriceMarkup="Costo rinnovo cliente (anno)"
        labelColumnDateExpiry="Data rinnovo cliente"
        hideProfitColumn={true}
        // Sorting
        sortStatusService={sortStatusService}
        setSortStatusService={setSortStatusService}
        sortStatusServiceSh={sortStatusServiceSh}
        setSortStatusServiceSh={setSortStatusServiceSh}
      />
    </>
  );
};

export default ClientServices;
