import { useMemo } from 'react';

import { Box, Group, rem, Tooltip } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';
import { dateMinusPlusDays, dateToDateString } from '@utils/date';

interface ExpireDateWithTooltipProps {
  date: Date;
}

export default function ExpireDateWithTooltip({
  date,
}: ExpireDateWithTooltipProps) {
  const now = new Date();

  const tooltipLabel = useMemo(() => {
    const dateExpiry = new Date(date);
    dateExpiry.setHours(0, 0, 0, 0); // Set hours 00.00
    const currentDate = new Date(now);
    currentDate.setHours(0, 0, 0, 0); // Set hours 00.00

    const daysDifference = Math.floor(
      (dateExpiry.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
    );

    let tooltipLabel = '';
    if (dateExpiry < currentDate) {
      tooltipLabel = `Scaduto da ${Math.abs(daysDifference)} giorni`;
    } else if (daysDifference <= 30) {
      tooltipLabel = `In scadenza tra ${daysDifference} giorni`;
    }

    return tooltipLabel;
  }, [date]);

  return (
    <Group gap="xs" align="center" wrap="nowrap" lh="0.9rem">
      {dateToDateString(date)}
      <Tooltip label={tooltipLabel}>
        <Box
          style={{
            height: rem('1rem'),
            width: rem('1rem'),
          }}
        >
          {(date < now || dateMinusPlusDays(date, -30) < now) && (
            <IconCircleFilled
              style={{
                color:
                  date < now
                    ? '#a12828'
                    : dateMinusPlusDays(date, -30) < now
                      ? '#cc8a21'
                      : undefined,
                height: rem('1rem'),
                width: rem('1rem'),
              }}
            />
          )}
        </Box>
      </Tooltip>
    </Group>
  );
}
