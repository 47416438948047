import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

// Define the interface for each banner
export interface Banner {
  title: string;
  content: string;
}

// LayoutProps now accepts an array of banners
export interface LayoutProps {
  title: string;
  loadingText?: string;
  backLink?: {
    title: string;
    to: string;
  };
  banners?: Banner[]; // Array of banners
}

// The context is updated to accept an array of banners
export interface ContextType {
  setTitle: (title: string) => void;
  setBackLink: (backLink: { title: string; to: string } | undefined) => void;
  setBanners: (banners: Banner[] | undefined) => void;
  setLoadingText: (loadingText: string | undefined) => void;
}

// The useLayoutProps hook with banner array handling
export default function useLayoutProps(props: LayoutProps) {
  const { setTitle, setBackLink, setBanners, setLoadingText } =
    useOutletContext<ContextType>();

  useEffect(() => {
    setTitle(props.title);

    // If backLink exists, set it
    if (props.backLink) {
      setBackLink({
        title: props.backLink.title,
        to: props.backLink.to,
      });
    }

    // Set banners if provided
    if (props.banners) {
      setBanners(props.banners);
    }

    // Set loading text
    setLoadingText(props.loadingText);

    // Cleanup function to reset values
    return () => {
      setTitle('');
      setBackLink(undefined);
      setBanners(undefined); // Reset banners to undefined
      setLoadingText(undefined);
    };
  }, [
    props.backLink,
    props.backLink?.title,
    props.backLink?.to,
    props.banners, // Dependency for banners
    props.loadingText,
    props.title,
    setBackLink,
    setBanners,
    setLoadingText,
    setTitle,
  ]);
}
