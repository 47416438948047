import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Center,
  Group,
  Pagination,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { setMinHeightTable } from '@utils/general';

import {
  Agency,
  AgencyApiQueryParams,
  SortBy,
  useDeleteAgencyMutation,
  useGetAgenciesCountQuery,
  useGetAgenciesQuery,
} from '@api/agencies/agencies.api';
import { generateAgenciesMock } from '@api/agencies/agencies.mock';
import { isApiError } from '@api/index';

import useLayoutProps from '@components/layout/useLayoutProps';
import ModalAddAgency from '@components/ModalAddAgency';
import ModalPatchAgency from '@components/ModalPatchAgency';
import SearchAddContainer from '@components/SearchAddContainer';

export default function Agencies() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const theme = useMantineTheme();
  useLayoutProps({
    title: 'Agenzie',
  });
  const { colorScheme } = useMantineColorScheme();

  // ==========================================================================
  // State
  // ==========================================================================

  const [filters, setFilters] = useState<AgencyApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Agency>>({
    columnAccessor: 'email',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f: AgencyApiQueryParams) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as SortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);
  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: agencyCount = { count: 0 }, isLoading: isLoadingAgencyCount } =
    useGetAgenciesCountQuery(searchQuery);

  const {
    data: agencies = generateAgenciesMock(2),
    isLoading,
    error: errorAgencies,
  } = useGetAgenciesQuery({
    ...filters,
    searchQuery,
  });

  const [agencyDelete, { isLoading: deleteLoading }] =
    useDeleteAgencyMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onEditAgencyClick = async (agency: Agency) => {
    openModal({
      title: 'Modifica agenzia',
      children: <ModalPatchAgency agency={agency} />,
      size: 'lg',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(agencyCount.count / filters.pageLength!);

  return (
    <SearchAddContainer
      searchPlaceholder="Ricerca per ragione sociale o email"
      searchValue={filters.searchQuery}
      onSearchChange={(newValue) =>
        setFilters({ ...filters, searchQuery: newValue })
      }
      onActionButtonClick={() =>
        openModal({
          title: 'Inserisci nuova agenzia',
          children: <ModalAddAgency />,
          size: 'lg',
        })
      }
    >
      <DataTable
        highlightOnHover
        minHeight={setMinHeightTable(agencies)}
        withRowBorders
        striped
        styles={{
          root: {
            borderRadius: theme.radius.md,
            boxShadow: theme.shadows.lg,
          },
          header: {
            backgroundColor: colorScheme === 'dark' ? '#1e2023' : '',
          },
        }}
        records={agencies}
        columns={[
          { accessor: 'agencyName', title: 'Ragione sociale', sortable: true },
          { accessor: 'email', title: 'Email', sortable: true },
          { accessor: 'phone', title: 'Telefono' },
          {
            accessor: 'actions',
            title: '',
            width: '0',
            render: (record: Agency) => (
              <Group justify="flex-end" wrap="nowrap">
                <ActionIcon
                  title="Modifica"
                  onClick={() => onEditAgencyClick(record)}
                >
                  <IconPencil />
                </ActionIcon>
                <ActionIcon
                  title="Elimina"
                  loading={deleteLoading}
                  onClick={() => {
                    openConfirmModal({
                      title: 'Eliminazione agenzia',
                      size: 'lg',
                      children: (
                        <Text>
                          Stai per eliminare l'agenzia {record.agencyName}.
                          Sicuro di voler continuare?
                        </Text>
                      ),
                      labels: {
                        confirm: 'Conferma eliminazione',
                        cancel: 'Annulla',
                      },
                      confirmProps: { color: 'red' },
                      onConfirm: async () => {
                        try {
                          await agencyDelete({ id: record.id }).unwrap();

                          showNotification({
                            title: 'Eliminazione agenzia',
                            message:
                              "L'eliminazione dell'agenzia avvenuta con successo",
                          });
                        } catch (e) {
                          if (isApiError(e)) {
                            if (e.status === 404) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            } else if (e.status === 405) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            } else {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        }
                      },
                    });
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        fetching={isLoading || isLoadingAgencyCount}
        noRecordsText={
          errorAgencies
            ? 'Errore. Ricaricare la pagina'
            : 'Nessun agenzia trovata'
        }
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      ></DataTable>

      {agencies.length > 0 && totalPages > 1 && (
        <Center mt="xl">
          <Pagination
            value={filters.page}
            onChange={(newPage) => setFilters({ ...filters, page: newPage })}
            total={totalPages}
          />
        </Center>
      )}
    </SearchAddContainer>
  );
}
