import { ReactNode } from 'react';

import { Box, Button, Group, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import SearchBar from '@components/searchBar/SearchBar';

interface SearchAddContainerProps {
  title?: string;
  children: ReactNode;
  additionalFilters?: ReactNode;
  onActionButtonClick?: () => void;
  actionButtonLabel?: string;
  rightSection?: ReactNode;
  searchPlaceholder?: string;
  searchValue?: string;
  onSearchChange: (newValue: string) => void;
}

export default function SearchAddContainer({
  title,
  additionalFilters,
  onActionButtonClick,
  actionButtonLabel = 'Aggiungi',
  rightSection,
  searchPlaceholder,
  searchValue,
  onSearchChange,
  children,
}: SearchAddContainerProps) {
  return (
    <Box mb="lg">
      {title && (
        <Title order={4} mb="md">
          {title}
        </Title>
      )}
      <Group justify="space-between" mb="lg">
        <Group>
          <SearchBar
            placeholder={searchPlaceholder || ''}
            value={searchValue}
            onChange={onSearchChange}
          />
          {additionalFilters}
        </Group>
        <Group>
          {rightSection
            ? rightSection
            : onActionButtonClick && (
                <Button
                  onClick={onActionButtonClick}
                  leftSection={<IconPlus />}
                >
                  {actionButtonLabel}
                </Button>
              )}
        </Group>
      </Group>
      {children}
    </Box>
  );
}
