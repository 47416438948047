import { DataTable, DataTableProps } from 'mantine-datatable';

import classes from './StandardDataTable.module.css';

export default function StandardDataTable<T>(props: DataTableProps<T>) {
  return (
    <DataTable<T>
      withRowBorders
      highlightOnHover
      striped
      classNames={{ root: classes.tableRoot, header: classes.tableHeader }}
      {...props}
    />
  );
}
