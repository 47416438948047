import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Divider,
  Group,
  rem,
  Select,
  Switch,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCategory, IconPencil, IconTrash } from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';
import { mapLabelprojectsCategory, setMinHeightTable } from '@utils/general';

import { isApiError } from '@api/index';
import {
  Project,
  ProjectApiQueryParams,
  ProjectsCategory,
  SortBy,
  useDeleteProjectMutation,
  useGetProjectCountQuery,
  useGetProjectsQuery,
  usePatchProjectMutation,
} from '@api/projects/projects.api';
import { generateDataProjects } from '@api/projects/projects.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ModalAddProject from '@components/ModalAddProject';
import ModalPatchProject from '@components/ModalPatchProject';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';

export default function Clients() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const theme = useMantineTheme();
  useLayoutProps({
    title: 'Progetti',
  });
  const { colorScheme } = useMantineColorScheme();

  // ==========================================================================
  // State
  // ==========================================================================

  const [filters, setFilters] = useState<ProjectApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
    projectsCategory: searchParams.get('projectsCategory')
      ? (searchParams.get('projectsCategory') as ProjectsCategory)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Project>>({
    columnAccessor: 'createdAt',
    direction: 'desc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((p: ProjectApiQueryParams) => ({
        ...p,
        sortBy: sortStatus.columnAccessor as SortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);
  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: ProjectsCount = { count: 0 },
    isLoading: isLoadingClientsCount,
  } = useGetProjectCountQuery(searchQuery);

  const {
    data: projects = generateDataProjects(6),
    isLoading,
    error: errorProjects,
  } = useGetProjectsQuery({
    ...filters,
    searchQuery,
  });
  const [projectDelete, { isLoading: deleteLoading }] =
    useDeleteProjectMutation();

  const [patchProject] = usePatchProjectMutation();

  const changeProjectToCompleted = async (id: number, completed: boolean) => {
    try {
      await patchProject({
        id,
        body: { completed },
      }).unwrap();
    } catch (e) {
      console.error(e);
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile modificare il progetto',
      });
    }
  };

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onEditProjectClick = async (project: Project) => {
    openModal({
      title: 'Modifica progetto',
      children: <ModalPatchProject project={project} />,
      size: 'lg',
    });
  };

  const onProjectsCategoryChange = (value: ProjectsCategory) => {
    searchParams.set('projectsCategory', value);
    setFilters({
      ...filters,
      projectsCategory: value,
    });
  };

  const projectsCategoryFilter = (
    <Select
      key="type filter"
      value={filters.projectsCategory}
      onChange={(value: string | null) =>
        onProjectsCategoryChange(value as ProjectsCategory)
      }
      placeholder="Filtro categoria"
      data={[
        { value: 'all', label: 'Tutti' },
        { value: 'app', label: 'App' },
        { value: 'service', label: 'Servizio' },
        { value: 'uncategorized', label: 'Non categorizzati' },
      ]}
      allowDeselect={false}
    />
  );

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(ProjectsCount.count / filters.pageLength!);
  return (
    <SearchAddContainer
      searchPlaceholder="Ricerca per progetto o cliente"
      searchValue={filters.searchQuery}
      onSearchChange={(newValue) =>
        setFilters({ ...filters, searchQuery: newValue })
      }
      onActionButtonClick={() =>
        openModal({
          title: 'Inserisci nuovo progetto',
          size: 'xl',
          children: <ModalAddProject />,
        })
      }
      additionalFilters={projectsCategoryFilter}
    >
      <DataTable
        highlightOnHover
        minHeight={setMinHeightTable(projects as Project[])}
        withRowBorders
        striped
        styles={{
          root: {
            borderRadius: theme.radius.md,
            boxShadow: theme.shadows.lg,
          },
          header: {
            backgroundColor: colorScheme === 'dark' ? '#1e2023' : '',
          },
        }}
        records={projects as Project[]}
        columns={[
          {
            accessor: 'completed',
            title: 'Completato',
            width: '0%',
            render: (record: Project) => (
              <Switch
                checked={record.completed}
                onChange={(e) =>
                  changeProjectToCompleted(record.id, e.currentTarget.checked)
                }
                style={{ justifyContent: 'center' }}
              />
            ),
          },
          { accessor: 'name', title: 'Nome', sortable: true },
          {
            accessor: 'client.companyName',
            title: 'Cliente',
            render: (record: Project) =>
              record.client.type === 'company'
                ? record.client.companyName
                : record.client.name + ' ' + record.client.surname,
          },
          {
            accessor: 'client.ProjectsCategory',
            title: 'Categoria',
            width: '0%',
            render: (record: Project) =>
              mapLabelprojectsCategory(record.projectsCategory),
          },
          {
            accessor: 'createdAt',
            title: 'Data creazione',
            width: '0%',
            sortable: true,
            render: (record: Project) =>
              dateToDateString(new Date(record.createdAt)),
          },
          {
            accessor: 'actions',
            title: '',
            width: '0%',

            render: (record: Project) => (
              <Group justify="flex-end" wrap="nowrap">
                <Tooltip label="Servizi">
                  <ActionIcon
                    to={`/progetto/${record.id}/servizi`}
                    component={Link}
                  >
                    <IconCategory />
                  </ActionIcon>
                </Tooltip>
                <Divider orientation="vertical" size={rem('0.15rem')} />

                <ActionIcon
                  title="Modifica"
                  onClick={() => onEditProjectClick(record)}
                >
                  <IconPencil />
                </ActionIcon>
                <ActionIcon
                  title="Elimina"
                  loading={deleteLoading}
                  onClick={() => {
                    openConfirmModal({
                      title: 'Eliminazione progetto',
                      size: 'lg',
                      children: (
                        <Text>
                          Stai per eliminare il progetto {record.name}. Sicuro
                          di voler continuare?
                        </Text>
                      ),
                      labels: {
                        confirm: 'Conferma eliminazione',
                        cancel: 'Annulla',
                      },
                      confirmProps: { color: 'red' },
                      onConfirm: async () => {
                        try {
                          await projectDelete({ id: record.id }).unwrap();
                          showNotification({
                            title: 'Eliminazione progetto',
                            message:
                              "L'eliminazione del progetto è avvenuta con successo",
                          });
                        } catch (e) {
                          if (isApiError(e)) {
                            if (e.status === 404) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            } else if (e.status === 405) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            } else {
                              console.error(e);
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        }
                      },
                    });
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
        fetching={isLoading || isLoadingClientsCount}
        noRecordsText={
          errorProjects
            ? 'Errore. Ricaricare la pagina'
            : 'Nessun progetto trovato'
        }
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
      ></DataTable>
      {projects.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </SearchAddContainer>
  );
}
