import { FC, useEffect } from 'react';

import { MultiSelect } from '@mantine/core';

import { ProjectForSelector, useGetProjectsForSelectorQuery } from '@api/projects/projects.api';

interface MultiProjectsSelectorProps {
  onChange: (value: string[]) => void;
  value: string[];
  required?: boolean;
  disabled?: boolean;
  onProjectsFetched?: (projects: ProjectForSelector[]) => void;
}

const MultiProjectsSelector: FC<MultiProjectsSelectorProps> = ({
  onChange,
  value,
  required,
  disabled,
  onProjectsFetched,
}) => {
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading } = useGetProjectsForSelectorQuery();

  useEffect(() => {
    if (onProjectsFetched && data && data.length > 0) {
      onProjectsFetched(data);
    }
  }, [onProjectsFetched, data]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  // const handleOnCreate = (query: string): string => {
  //   openModal({
  //     title: 'Aggiungi progetto',
  //     children: <ModalAddProject initialProjectName={query} />,
  //   });

  //   return query;
  // };

  // ==========================================================================
  // Render
  // ==========================================================================
  // TODO: sono arrivato qui. vedere come gestire la modifica dei prezzi di rinnovo
  //TODO Ho tolto il creatabke e getCreateLabel perchè dalla versione 7 non è più disponibile fare questo
  // creatable
  // getCreateLabel={(query: string) => `+ Aggiungi progetto ${query}`}
  // onCreate={handleOnCreate}
  return (
    <>
      <MultiSelect
        searchable
        required={required}
        label="Progetti: "
        placeholder={value.length > 0 ? undefined : 'Seleziona progetti'}
        disabled={disabled || isLoading}
        nothingFoundMessage="Nessun progetto trovato"
        data={
          data
            ? data.map((project) => ({
                value: project.id.toString(),
                label: project.name,
              }))
            : []
        }
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default MultiProjectsSelector;
