import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Accordion,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  rem,
  Space,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconChevronLeft } from '@tabler/icons-react';
import { dateToDateString } from '@utils/date';

import { useGetResponseDataJobQuery } from '@api/jobs/jobs.api';
import { useImportNewExternalServicesShMutation } from '@api/servicessh/servicesSh.api';
import { ServiceShImported } from '@api/servicessh/servicesSh.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ModalConfirmImportServiceSh from '@components/ModalConfirmImportServiceSh';

import classes from './ServiceShImport.module.css';

const ServiceShImport: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const navigate = useNavigate();
  const { id } = useParams();

  useLayoutProps({ title: 'Importazione servizi Shellrent' });
  // ==========================================================================
  // State
  // ==========================================================================
  const [selectedServicesNew, setSelectedServicesNew] = useState<
    Record<number, boolean>
  >({});

  const [selectedServicesUpdate, setSelectedServicesUpdate] = useState<
    Record<number, boolean>
  >({});

  const [selectedServicesDelete, setSelectedServicesDelete] = useState<
    Record<number, boolean>
  >({});

  // ==========================================================================
  // Api
  // ==========================================================================

  const { data = [], isLoading: isLoadingJobs } = useGetResponseDataJobQuery({
    id: +id!,
  }) as { data: ServiceShImported; isLoading: boolean };

  const result = data as ServiceShImported;

  useEffect(() => {
    // Set initial newServices check status
    if (result) {
      const tempSelectedServicesNew: Record<number, boolean> = {};

      const tempSelectedServicesUpdate: Record<number, boolean> = {};

      const tempSelectedServicesDelete: Record<number, boolean> = {};

      result.newServices?.forEach((service) => {
        if (!service.readonly) {
          tempSelectedServicesNew[service.shellrentId] = true;
        }
      });

      result.updatedServices?.forEach(
        (service) =>
          (tempSelectedServicesUpdate[service.new.shellrentId] = true),
      );

      result.deletedServices?.forEach((service) => {
        if (!service.readonly) {
          tempSelectedServicesDelete[service.shellrentId] = true;
        }
      });

      setSelectedServicesNew(tempSelectedServicesNew);
      setSelectedServicesUpdate(tempSelectedServicesUpdate);
      setSelectedServicesDelete(tempSelectedServicesDelete);
    }
  }, [result]);

  const [importServices, { isLoading: isImportServicesLoading }] =
    useImportNewExternalServicesShMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleImportServices = async () => {
    try {
      const selectedServicesAll = {
        ...selectedServicesNew,
        ...selectedServicesUpdate,
        ...selectedServicesDelete,
      };
      await importServices({
        jobId: +id!,
        services: Object.entries(selectedServicesAll)
          .filter((x) => x[1])
          .map((x) => +x[0]),
      }).unwrap();

      showNotification({
        title: 'Servizi gestiti',
        message: 'I servizi selezionati sono stati gestiti correttamente',
      });

      navigate('/servizi');
    } catch {
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Errore nella gestione dei servizi',
      });
    }
  };

  // New Services
  const handleClickCheckNew = (val: boolean) => {
    // Copia lo stato corrente di selectedServicesNew
    const newSelectedServices = { ...selectedServicesNew };

    // Imposta tutti i valori di selectedServicesNew a true
    Object.keys(newSelectedServices).forEach((key) => {
      newSelectedServices[+key] = val;
    });

    // Aggiorna lo stato con i nuovi valori
    setSelectedServicesNew(newSelectedServices);
  };

  // Delete Services
  const handleClickCheckDelete = (val: boolean) => {
    // Copia lo stato corrente di selectedServicesNew
    const deletedSelectedServices = { ...selectedServicesDelete };

    // Imposta tutti i valori di selectedServicesNew a true
    Object.keys(deletedSelectedServices).forEach((key) => {
      deletedSelectedServices[+key] = val;
    });

    // Aggiorna lo stato con i nuovi valori
    setSelectedServicesDelete(deletedSelectedServices);
  };

  // Update Services
  const handleClickCheckUpdate = (val: boolean) => {
    // Copia lo stato corrente di selectedServicesNew
    const updatedSelectedServices = { ...selectedServicesUpdate };

    // Imposta tutti i valori di selectedServicesNew a true
    Object.keys(updatedSelectedServices).forEach((key) => {
      updatedSelectedServices[+key] = val;
    });

    // Aggiorna lo stato con i nuovi valori
    setSelectedServicesUpdate(updatedSelectedServices);
  };

  // Open modal confirm actions
  const onConfirmServiceShClick = () => {
    const data = {
      newServices: Object.values(selectedServicesNew).filter(
        (value) => value === true,
      ).length,
      updateServices: Object.values(selectedServicesUpdate).filter(
        (value) => value === true,
      ).length,
      deleteServices: Object.values(selectedServicesDelete).filter(
        (value) => value === true,
      ).length,
    };

    openConfirmModal({
      title: 'Importazione servizi',
      children: (
        <ModalConfirmImportServiceSh data={data}></ModalConfirmImportServiceSh>
      ),
      size: 'lg',
      labels: {
        confirm: 'Importa servizi',
        cancel: 'Annulla',
      },
      onConfirm: handleImportServices,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <Container fluid>
      {![
        result.deletedServices,
        result.newServices,
        result.updatedServices,
      ].some((services) => services?.length > 0) ? (
        <>
          <Button
            component={Link}
            to="/servizi"
            leftSection={<IconChevronLeft />}
          >
            Torna ai servizi
          </Button>
          <Space h="xl" />
          <Text>Nessun servizio trovato</Text>
        </>
      ) : (
        <>
          <Box>
            <LoadingOverlay
              visible={isLoadingJobs}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: 'blue', type: 'oval' }}
            />
            <Grid>
              {/* New services */}
              <Grid.Col span={{ base: 12, md: 4 }}>
                <Container className={classes.anagrafe}>
                  <div style={{ padding: rem('1rem'), width: '100%' }}>
                    <Text fw="bold" fz="1.4rem" mb="sm">
                      Nuovi servizi ({result.newServices?.length}):
                    </Text>
                    {result.newServices?.length > 0 && (
                      <Group gap="xl" mb="lg">
                        <Button onClick={() => handleClickCheckNew(true)}>
                          Seleziona tutto
                        </Button>
                        <Button onClick={() => handleClickCheckNew(false)}>
                          Deseleziona tutto
                        </Button>
                      </Group>
                    )}

                    {result.newServices?.length === 0 && (
                      <Text fs="italic" fz="1.1rem">
                        Nessun servizio trovato
                      </Text>
                    )}
                    {/* {result.newServices?.map((service) => {
                  if (service.readonly && service.readonly === true) {
                    return (
                      <Tooltip
                        label={`${service.shellrentId} - ${service.name}`}
                      >
                        <Text key={service.shellrentId} pb="sm">
                          {service.shellrentId} - {service.name}
                        </Text>
                      </Tooltip>
                    );
                  }

                  return (
                    <>
                      <Checkbox
                        key={service.shellrentId}
                        mb="xs"
                        pl={service.primaryServiceId ? 'xl' : 0}
                        checked={selectedServicesNew[service.shellrentId]}
                        onChange={(e) =>
                          setSelectedServicesNew({
                            ...selectedServicesNew,
                            [service.shellrentId]: e.currentTarget.checked,
                          })
                        }
                        label={
                          <Tooltip
                            label={`${service.shellrentId} - ${service.name}`}
                          >
                            <Text
                              fs={
                                service.primaryServiceId ? 'italic' : undefined
                              }
                              lineClamp={1}
                            >
                              {`${service.shellrentId} - ${service.name}`}
                            </Text>
                          </Tooltip>
                        }
                      />
                      {isLoadingJobs && <Space h="xs" />}
                    </>
                  );
                })} */}
                    {result.newServices?.map((service) => {
                      const handleServiceChange = (isChecked: boolean) => {
                        const updatedSelectedServicesNew = {
                          ...selectedServicesNew,
                        };

                        // Se è un servizio primario e stiamo cercando di deselezionarlo
                        if (!isChecked && !service.primaryServiceId) {
                          // Controlla se ci sono servizi secondari selezionati
                          const hasSelectedSecondary = result.newServices?.some(
                            (s) =>
                              s.primaryServiceId === service.shellrentId &&
                              updatedSelectedServicesNew[s.shellrentId],
                          );

                          if (hasSelectedSecondary) {
                            // Non permettere la deselezione del primario se c'è un secondario selezionato
                            return;
                          }
                        }

                        // Aggiorna la selezione per il servizio attuale
                        updatedSelectedServicesNew[service.shellrentId] =
                          isChecked;

                        // Se è un servizio secondario e viene selezionato, seleziona anche il primario
                        if (service.primaryServiceId && isChecked) {
                          const primaryService = result.newServices?.find(
                            (s) => s.shellrentId === service.primaryServiceId,
                          );

                          if (primaryService && !primaryService.readonly) {
                            updatedSelectedServicesNew[
                              service.primaryServiceId
                            ] = true;
                          }
                        }

                        setSelectedServicesNew(updatedSelectedServicesNew);
                      };

                      // Determina se il checkbox del servizio primario deve essere disabilitato
                      const isPrimaryDisabled =
                        !service.primaryServiceId &&
                        result.newServices?.some(
                          (s) =>
                            s.primaryServiceId === service.shellrentId &&
                            selectedServicesNew[s.shellrentId],
                        );

                      if (service.readonly && service.readonly === true) {
                        return (
                          <Tooltip
                            label={`${service.shellrentId} - ${service.name}`}
                          >
                            <Text key={service.shellrentId} pb="sm">
                              {service.shellrentId} - {service.name}
                            </Text>
                          </Tooltip>
                        );
                      }

                      return (
                        <>
                          <Checkbox
                            disabled={isPrimaryDisabled}
                            key={service.shellrentId}
                            mb="xs"
                            pl={service.primaryServiceId ? 'xl' : 0}
                            checked={selectedServicesNew[service.shellrentId]}
                            onChange={(e) =>
                              handleServiceChange(e.currentTarget.checked)
                            }
                            label={
                              <Tooltip
                                label={`${service.shellrentId} - ${service.name}`}
                              >
                                <Text
                                  fs={
                                    service.primaryServiceId
                                      ? 'italic'
                                      : undefined
                                  }
                                  lineClamp={1}
                                >
                                  {`${service.shellrentId} - ${service.name}`}
                                </Text>
                              </Tooltip>
                            }
                          />
                          {isLoadingJobs && <Space h="xs" />}
                        </>
                      );
                    })}
                  </div>
                </Container>
              </Grid.Col>

              {/* Updated services */}
              <Grid.Col span={{ base: 12, md: 4 }}>
                <Container className={classes.anagrafe}>
                  <div style={{ padding: rem('1rem'), width: '100%' }}>
                    <Text fw="bold" fz="1.4rem" mb="sm">
                      Servizi modificati ({result.updatedServices?.length}):
                    </Text>
                    {result.updatedServices?.length > 0 && (
                      <Group gap="xl" mb="lg">
                        <Button onClick={() => handleClickCheckUpdate(true)}>
                          Seleziona tutto
                        </Button>
                        <Button onClick={() => handleClickCheckUpdate(false)}>
                          Deseleziona tutto
                        </Button>
                      </Group>
                    )}

                    {result.updatedServices?.length === 0 && (
                      <Text fs="italic" fz="1.1rem">
                        Nessun servizio trovato
                      </Text>
                    )}
                    <Accordion>
                      {result.updatedServices?.map((service) => (
                        <Accordion.Item
                          key={service.new.shellrentId}
                          value={service.new.shellrentId.toString()}
                        >
                          <Accordion.Control>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '15px',
                              }}
                            >
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={
                                  selectedServicesUpdate[
                                    service.new.shellrentId
                                  ]
                                }
                                onChange={(e) =>
                                  setSelectedServicesUpdate({
                                    ...selectedServicesUpdate,
                                    [service.new.shellrentId]:
                                      e.currentTarget.checked,
                                  })
                                }
                              />
                              <Tooltip
                                label={
                                  service.old.shellrentId +
                                  ' - ' +
                                  service.old.name
                                }
                              >
                                <Text lineClamp={1}>
                                  {service.old.shellrentId} - {service.old.name}
                                </Text>
                              </Tooltip>
                            </div>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <Stack>
                              {service.primary && (
                                <Group gap={0}>
                                  <Text c="dimmed" fw={600} fz="sm">
                                    SERVIZIO PRIMARIO
                                    <Text fs="italic" c="dimmed" fz="sm">
                                      {service.primary.name}
                                    </Text>
                                  </Text>
                                </Group>
                              )}
                              {service.old.name !== service.new.name && (
                                <Text>
                                  Nome:{' '}
                                  <span className={classes.highlightDel}>
                                    {service.old.name}
                                  </span>
                                  →{' '}
                                  <span className={classes.highlightApp}>
                                    {service.new.name}
                                  </span>
                                </Text>
                              )}
                              {service.old.purchasePrice !==
                                service.new.purchasePrice && (
                                <Text>
                                  Prezzo di acquisto:{' '}
                                  <span className={classes.highlightDel}>
                                    €{service.old.purchasePrice}
                                  </span>
                                  →{' '}
                                  <span className={classes.highlightApp}>
                                    €{service.new.purchasePrice}
                                  </span>
                                </Text>
                              )}
                              {service.old.renewPrice !==
                                service.new.renewPrice && (
                                <Text>
                                  Prezzo di rinnovo:{' '}
                                  <span className={classes.highlightDel}>
                                    €{service.old.renewPrice}
                                  </span>
                                  →{' '}
                                  <span className={classes.highlightApp}>
                                    €{service.new.renewPrice}
                                  </span>
                                </Text>
                              )}
                              {dateToDateString(
                                new Date(service.old.dateExpiry),
                              ) !==
                                dateToDateString(
                                  new Date(service.new.dateExpiry),
                                ) && (
                                <Text>
                                  Data di scadenza:{' '}
                                  <span className={classes.highlightDel}>
                                    {dateToDateString(
                                      new Date(service.old.dateExpiry),
                                    )}
                                  </span>
                                  →{' '}
                                  <span className={classes.highlightApp}>
                                    {dateToDateString(
                                      new Date(service.new.dateExpiry),
                                    )}
                                  </span>
                                </Text>
                              )}
                              {service.old.renewRecurrence !==
                                service.new.renewRecurrence && (
                                <Text>
                                  Ricorrenza:{' '}
                                  <span className={classes.highlightDel}>
                                    {service.old.renewRecurrence}
                                  </span>
                                  →{' '}
                                  <span className={classes.highlightApp}>
                                    {service.new.renewRecurrence}
                                  </span>
                                </Text>
                              )}
                            </Stack>
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </Container>
              </Grid.Col>

              {/* Deleted services */}
              <Grid.Col span={{ base: 12, md: 4 }}>
                <Container className={classes.anagrafe}>
                  <div style={{ padding: rem('1rem'), width: '100%' }}>
                    <Text fw="bold" fz="1.4rem" mb="sm">
                      Servizi eliminati ({result.deletedServices?.length}):
                    </Text>
                    {result.deletedServices?.length > 0 && (
                      <Group gap="xl" mb="lg">
                        <Button onClick={() => handleClickCheckDelete(true)}>
                          Seleziona tutto
                        </Button>
                        <Button onClick={() => handleClickCheckDelete(false)}>
                          Deseleziona tutto
                        </Button>
                      </Group>
                    )}
                    {result.deletedServices?.length === 0 && (
                      <Text fs="italic" fz="1.1rem">
                        Nessun servizio trovato
                      </Text>
                    )}
                    {result.deletedServices?.map((service) => {
                      const handleServiceChange = (isChecked: boolean) => {
                        const updatedSelectedServicesDeleted = {
                          ...selectedServicesDelete,
                        };

                        // Se è un servizio primario e viene selezionato o deselezionato
                        if (!service.primaryServiceId) {
                          // Seleziona o deseleziona tutti i servizi secondari associati
                          result.deletedServices?.forEach((s) => {
                            if (s.primaryServiceId === service.shellrentId) {
                              updatedSelectedServicesDeleted[s.shellrentId] =
                                isChecked;
                            }
                          });
                        }

                        // Aggiorna la selezione per il servizio attuale
                        updatedSelectedServicesDeleted[service.shellrentId] =
                          isChecked;

                        // Se è un servizio secondario e viene selezionato, seleziona anche il primario
                        if (service.primaryServiceId && isChecked) {
                          const primaryService = result.deletedServices?.find(
                            (s) => s.shellrentId === service.primaryServiceId,
                          );

                          if (primaryService && !primaryService.readonly) {
                            updatedSelectedServicesDeleted[
                              service.primaryServiceId
                            ] = true;
                          }
                        }

                        setSelectedServicesDelete(
                          updatedSelectedServicesDeleted,
                        );
                      };

                      // Disabilita il checkbox per i servizi secondari
                      const isSecondaryDisabled = service.primaryServiceId
                        ? result.deletedServices?.some(
                            (s) =>
                              s.shellrentId === service.primaryServiceId &&
                              s.readonly === false,
                          )
                        : false;

                      if (service.readonly && service.readonly === true) {
                        return (
                          <Tooltip
                            label={`${service.shellrentId} - ${service.name}`}
                          >
                            <Group wrap="nowrap">
                              <Text
                                key={service.shellrentId}
                                mb="xs"
                                lineClamp={1}
                              >
                                {service.shellrentId} - {service.name}
                              </Text>
                            </Group>
                          </Tooltip>
                        );
                      }

                      return (
                        <>
                          <Checkbox
                            disabled={isSecondaryDisabled}
                            key={service.shellrentId}
                            mb="xs"
                            pl={service.primaryServiceId ? 'xl' : 0}
                            checked={
                              selectedServicesDelete[service.shellrentId]
                            }
                            onChange={(e) =>
                              handleServiceChange(e.currentTarget.checked)
                            }
                            label={
                              <Tooltip
                                label={`${service.shellrentId} - ${service.name}`}
                              >
                                <Text
                                  fs={
                                    service.primaryServiceId
                                      ? 'italic'
                                      : undefined
                                  }
                                  lineClamp={1}
                                >
                                  {`${service.shellrentId} - ${service.name}`}
                                </Text>
                              </Tooltip>
                            }
                          />
                          {isLoadingJobs && <Space h="xs" />}
                        </>
                      );
                    })}
                  </div>
                </Container>
              </Grid.Col>
            </Grid>
          </Box>
          <Space h="md" />
          <Group w="100%" justify="end">
            <Button
              mt="xl"
              // onClick={handleImportServices}
              loading={isImportServicesLoading}
              className={classes.btnFixed}
              onClick={() => onConfirmServiceShClick()}
            >
              Gestisci selezionati
            </Button>
          </Group>
        </>
      )}
    </Container>
  );
};

export default ServiceShImport;
