export const getYearlyPriceFromRecurrence = (
  price: number,
  recurrence: string,
): number => {
  if (recurrence.startsWith('M')) {
    return price * 12;
  }

  return price;
};
