import { generateMockValue } from '@utils/generateMockData';

export interface ServiceShImported {
  newServices: NewServices[];
  updatedServices: UpdatedServices[];
  deletedServices: DeletedServices[];
}

interface UpdatedServices {
  primary: ServiceUpdatableData | null;
  new: ServiceUpdatableData;
  old: ServiceUpdatableData;
}

interface ServiceUpdatableData {
  shellrentId: number;
  name: string;
  purchasePrice: number;
  renewPrice: number;
  dateExpiry: Date;
  toRenew: boolean;
  primaryServiceId: number | null;
  dataHash: string | null;
  readonly?: boolean;
  renewRecurrence: string;
}

interface DeletedServices {
  primaryServiceId: number | null;
  name: string;
  shellrentId: number;
  readonly?: boolean;
}

interface NewServices {
  shellrentId: number;
  name: string;
  purchasePrice: number;
  renewPrice: number;
  dateExpiry: Date;
  toRenew: boolean;
  primaryServiceId: number | null;
  dataHash: string | null;
  readonly?: boolean;
}

export const generateDataServicessh = (count: number) => {
  return generateMockValue({
    type: 'array',
    minLength: count,
    maxLength: count,
    each: {
      type: 'object',
      values: {
        id: 'number',
        shellrentId: 'number',
        name: 'string',
        proformNote: 'string',
        // note: 'string',
        purchasePrice: 'number',
        renewPrice: 'number',
        renewPriceMarkup: 'number',
        toPay: 'boolean',
        toNotify: 'boolean',
        dateExpiry: 'string',
        toRenew: 'boolean',
        renewRecurrence: 'string',
        primaryServiceId: 'null',
        // primaryService: 'null',
        secondaryServicesMapped: 'null',
        projects: {
          type: 'array',
          minLength: 3,
          maxLength: 3,
          each: {
            type: 'object',
            values: {
              projectId: 'number',
              renewPriceMarkup: 'number',
              renewRecurrence: 'string',
              toPay: 'boolean',
              toNotify: 'boolean',
              dateExpiry: 'string',
              project: {
                type: 'object',
                values: {
                  id: 'number',
                  name: 'string',
                  clientId: 'number',
                },
              },
            },
          },
        },
      },
    },
  });
};

export const generateDataShImported = () => {
  return generateMockValue({
    type: 'object',
    values: {
      newServices: {
        type: 'array',
        minLength: 5,
        maxLength: 5,
        each: {
          type: 'object',
          values: {
            name: 'string',
            shellrentId: 'number',
            primaryServiceId: 'number',
          },
        },
      },
      updatedServices: {
        type: 'array',
        minLength: 5,
        maxLength: 5,
        each: {
          type: 'object',
          values: {
            new: {
              type: 'object',
              values: {
                name: 'string',
                renewPrice: 'number',
                shellrentId: 'number',
                toRenew: 'boolean',
                purchasePrice: 'number',
                dateExpiry: 'date',
              },
            },
            old: {
              type: 'object',
              values: {
                name: 'string',
                renewPrice: 'number',
                shellrentId: 'number',
                toRenew: 'boolean',
                purchasePrice: 'number',
                dateExpiry: 'date',
              },
            },
          },
        },
      },
    },
  });
};
