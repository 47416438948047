import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { FC, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import {
  ActionIcon,
  Divider,
  Group,
  rem,
  Switch,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCategory, IconPencil, IconTrash } from '@tabler/icons-react';
import { dateToDateHourseString } from '@utils/date';
import { setMinHeightTable } from '@utils/general';

import { useGetSingleClientQuery } from '@api/clients/clients.api';
import { isApiError } from '@api/index';
import {
  Project,
  ProjectApiQueryParams,
  SortBy,
  useDeleteProjectMutation,
  useGetProjectClientCountQuery,
  useGetProjectsClientQuery,
  usePatchProjectMutation,
} from '@api/projects/projects.api';
import { generateDataProjects } from '@api/projects/projects.mock';

import useLayoutProps from '@components/layout/useLayoutProps';
import ModalPatchProject from '@components/ModalPatchProject';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';

const Projects: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const theme = useMantineTheme();
  const { id } = useParams();
  const { colorScheme } = useMantineColorScheme();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<ProjectApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Project>>({
    columnAccessor: 'createdAt',
    direction: 'desc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f: ProjectApiQueryParams) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as SortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: ProjectsCount = { count: 0 },
    isLoading: isLoadingClientsCount,
  } = useGetProjectClientCountQuery({
    id: +id!,
    searchQuery,
  });

  const {
    data: projects = generateDataProjects(6),
    isLoading,
    error: errorProjects,
  } = useGetProjectsClientQuery({
    id: +id!,
    params: { ...filters, searchQuery },
  });

  const [projectDelete, { isLoading: deleteLoading }] =
    useDeleteProjectMutation();

  const [patchProject] = usePatchProjectMutation();

  const changeProjectToCompleted = async (id: number, completed: boolean) => {
    try {
      await patchProject({
        id,
        body: { completed },
      }).unwrap();
    } catch {
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Impossibile modificare il progetto',
      });
    }
  };

  const { data: client } = useGetSingleClientQuery(+id!);

  useLayoutProps({
    title:
      client?.type === 'company'
        ? `Progetti di ${client?.companyName}`
        : `Progetti di ${client?.name} ${client?.surname}`,
  });
  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onEditProjectClick = (project: Project) => {
    openModal({
      title: 'Modifica progetto',
      children: <ModalPatchProject project={project} />,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  const totalPages = Math.ceil(ProjectsCount.count / filters.pageLength!);

  return (
    <>
      <SearchAddContainer
        searchPlaceholder="Ricerca per nome"
        searchValue={filters.searchQuery}
        onSearchChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      >
        <DataTable
          highlightOnHover
          minHeight={setMinHeightTable(projects as Project[])}
          withRowBorders
          striped
          styles={{
            root: {
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
            },
            header: {
              backgroundColor: colorScheme === 'dark' ? '#1e2023' : '',
            },
          }}
          records={projects as Project[]}
          columns={[
            {
              accessor: 'completed',
              title: 'Completato',
              render: (record: Project) => (
                <Switch
                  checked={record.completed}
                  onChange={(e) =>
                    changeProjectToCompleted(record.id, e.currentTarget.checked)
                  }
                  style={{ justifyContent: 'center' }}
                />
              ),
            },
            { accessor: 'name', title: 'Nome', sortable: true },
            {
              accessor: 'client.companyName',
              title: 'Cliente',
              render: (record: Project) =>
                record.client.type === 'company'
                  ? record.client.companyName
                  : record.client.name + ' ' + record.client.surname,
            },
            {
              accessor: 'createdAt',
              title: 'Data creazione',
              sortable: true,
              render: (record: Project) =>
                dateToDateHourseString(new Date(record.createdAt)),
            },
            {
              accessor: 'actions',
              title: '',
              width: '0%',
              render: (record: Project) => (
                <Group justify="flex-end" wrap="nowrap">
                  <Tooltip label="Servizi">
                    <ActionIcon
                      to={`/progetto/${record.id}/servizi`}
                      component={Link}
                    >
                      <IconCategory />
                    </ActionIcon>
                  </Tooltip>
                  <Divider orientation="vertical" size={rem('0.15rem')} />

                  <ActionIcon
                    title="Modifica"
                    onClick={() => onEditProjectClick(record)}
                  >
                    <IconPencil />
                  </ActionIcon>

                  <ActionIcon
                    title="Elimina"
                    loading={deleteLoading}
                    onClick={() => {
                      openConfirmModal({
                        title: 'Eliminazione progetto',
                        size: 'lg',
                        children: (
                          <Text>
                            Stai per eliminare il progetto {record.name}. Sicuro
                            di voler continuare?
                          </Text>
                        ),
                        labels: {
                          confirm: 'Conferma eliminazione',
                          cancel: 'Annulla',
                        },
                        confirmProps: { color: 'red' },
                        onConfirm: async () => {
                          try {
                            await projectDelete({ id: record.id }).unwrap();
                            showNotification({
                              title: 'Eliminazione progetto',
                              message:
                                "L'eliminazione del progetto è avvenuta con successo",
                            });
                          } catch (e) {
                            console.error(e);
                            if (isApiError(e)) {
                              showNotification({
                                color: 'red',
                                title: 'Errore',
                                message: `${e.data.message}`,
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
          fetching={isLoading || isLoadingClientsCount}
          noRecordsText={
            errorProjects
              ? 'Errore. Ricaricare la pagina'
              : 'Nessun progetto trovato'
          }
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
        ></DataTable>

        {projects.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </SearchAddContainer>
    </>
  );
};

export default Projects;
