import { UseFormReturnType } from '@mantine/form';

import { isApiError } from '@api/index';
import { ProjectsCategory } from '@api/projects/projects.api';

export const handleSubmitError = <T>(
  e: unknown,
  form: UseFormReturnType<T>,
) => {
  if (isApiError(e)) {
    if (e.status === 400) {
      form.setErrors({ general: e.data.message, ...e.data.errors });
    } else if (e.status === 401) {
      form.setErrors({ general: e.data.message });
    } else {
      console.error(e);
      form.setErrors({
        general: 'Errore inatteso. Per favore riprova più tardi.',
      });
    }
  } else {
    console.error(e);
    form.setErrors({
      general: 'Errore inatteso. Per favore riprova più tardi.',
    });
  }
};

export const setMinHeightTable = <T>(arr: T[]): number =>
  arr.length > 0 ? 0 : 120;

export const mapLabelprojectsCategory = (data: ProjectsCategory | null) => {
  switch (data) {
    case 'app':
      return 'App';
    case 'service':
      return 'Servizio';
    case 'website':
      return 'Sito web';
    default:
      return '-';
  }
};

export const capitalizeFirstLetter = (data: string) => {
  return data.charAt(0).toUpperCase() + data.slice(1);
};

export const mapLabelRenewRecurrence = (input: string) => {
  // Verifica se l'input riguarda i mesi o gli anni
  if (input.startsWith('M')) {
    const interval = parseInt(input.substring(1), 10);

    if (interval === 1) {
      return 'Ogni mese';
    }
    return `Ogni ${interval} mesi`;
  } else if (input.startsWith('Y')) {
    const interval = parseInt(input.substring(1), 10);

    if (interval === 1) {
      return 'Ogni anno';
    }
    return `Ogni ${interval} anni`;
  }
};
